module.exports.html5logo = color => `<svg width="270" height="375" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">

 <g>
  <title>background</title>
  <rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/>
 </g>
 <g>
  <title>Layer 1</title>
  <g id="svg_1" fill="#000000" transform="translate(0,375) scale(0.10000000149011612,-0.10000000149011612) ">
   <path fill="${color}" id="svg_2" d="m281,3486l0,-256l83,3l82,3l3,83l3,82l74,0l75,0l0,-85l0,-86l83,3l82,3l3,253l2,252l-85,0l-85,0l0,-85l0,-85l-75,0l-74,0l-3,83l-3,82l-82,3l-83,3l0,-256z"/>
   <path fill="${color}" id="svg_3" d="m840,3655l0,-85l75,0l75,0l0,-170l0,-170l85,0l85,0l0,170l0,170l75,0l75,0l0,85l0,85l-235,0l-235,0l0,-85z"/>
   <path fill="${color}" id="svg_4" d="m1382,3488l3,-253l82,-3l82,-3l3,123l3,123l52,-83c29,-45 56,-82 60,-82c5,1 31,37 58,80l50,80l5,-117l5,-118l83,-3l82,-3l0,256l0,255l-88,0l-88,0l-49,-85c-28,-46 -53,-85 -56,-85c-4,0 -30,38 -58,85l-53,85l-89,0l-89,0l2,-252z"/>
   <path fill="${color}" id="svg_5" d="m2034,3727c-2,-7 -3,-120 -2,-252l3,-240l203,-3l202,-2l0,85l0,85l-115,0l-115,0l0,170l0,170l-85,0c-60,0 -87,-4 -91,-13z"/>
   <path fill="${color}" id="svg_6" d="m37,2978c5,-63 44,-496 83,-943c22,-247 49,-553 60,-680c11,-126 27,-297 35,-380c7,-82 24,-267 36,-410c13,-143 23,-261 24,-262c1,-2 79,-24 420,-118c88,-24 274,-76 412,-114l253,-70l253,70c138,38 324,90 412,114c342,94 419,116 420,118c1,1 11,119 24,262c23,272 51,576 76,855c17,185 133,1494 138,1553l3,37l-1326,0l-1326,0l3,-32zm2153,-552c0,-40 -17,-226 -26,-275l-6,-34l-628,7c-392,4 -631,3 -634,-3c-7,-11 21,-318 30,-332c3,-6 235,-7 605,-3l599,7l0,-24c-1,-72 -86,-998 -92,-1005c-2,-2 -78,-23 -168,-48c-91,-25 -239,-66 -330,-92c-146,-41 -170,-45 -210,-36c-54,11 -630,171 -641,177c-9,6 -16,62 -34,285c-8,102 -18,200 -20,218l-6,32l165,0l165,0l5,-32c3,-18 10,-80 16,-137c6,-58 12,-106 14,-108c2,-2 46,-15 97,-30c52,-14 131,-36 176,-49l83,-23l184,50c102,27 187,49 189,49c5,0 24,177 35,328l8,102l-573,0l-572,0l-5,43c-3,23 -12,121 -21,217c-19,224 -53,607 -61,688l-6,62l831,0l831,0l0,-34z"/>
  </g>
 </g>
</svg>`

module.exports.rdpclientLogo = color => `<svg width="380" height="300" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">

<g>
 <title>background</title>
 <rect fill="none" id="canvas_background" height="302" width="382" y="-1" x="-1"/>
</g>
<g>
 <title>Layer 1</title>
 <path fill="${color}" id="svg_2" d="m6.700001,6.799994l-2.7,2.8l0,89.000001l0,89.000001l3.3,3.2l3.3,3.2l64.800001,0l64.800001,0l-0.4,21.9l-0.3,21.9l-3.1,3.9c-5.6,6.9 -6.8,7.2 -30.5,8.3c-22.9,1.1 -27.2,1.9 -35.200001,7c-4.6,2.9 -6.8,6 -8.1,11.2c-1.1,4.8 1.2,5.8 13.2,5.8c21.9,-0.1 35.000001,-1.5 45.300001,-4.9c9.5,-3.1 9.9,-3.1 30.1,-3.1c11.3,0 21.9,-0.3 23.6,-0.6l3,-0.6l-5.9,-12.2c-10.3,-21.5 -13.6,-37.400001 -12.6,-61.100001c0.4,-7.7 1.3,-16.8 2,-20.2c1.1,-5.3 1.1,-6.3 -0.1,-6.8c-0.7,-0.3 -29.7,-0.5 -64.300001,-0.5l-62.900001,0l0,-65.000001l0,-65.000001l125.000002,0l125.000002,0l0.2,24.2l0.3,24.3l4,0.3c4.4,0.3 19.6,2.8 23.3,3.8l2.2,0.6l0,-38.900001l0,-38.900001l-2.8,-2.7l-2.8,-2.7l-149.500002,0l-149.500002,0l-2.7,2.8z"/>
 <path fill="${color}" id="svg_3" d="m258.000005,99.499995c-35.900001,8.1 -63.900001,33.600001 -75.700001,69.000001c-2.4,7.3 -2.7,9.7 -3.1,26.7c-0.3,16.6 -0.2,19.5 1.8,27c2.6,10.2 9.4,24.9 15.5,33.4c12.4,17.4 33.800001,32.5 55.000001,39.100001c5,1.5 9.4,1.8 26.5,1.8c18.7,0 21.2,-0.2 28,-2.3c15.9,-5.1 31.3,-14.4 43.100001,-26.1c17.3,-17.3 27.1,-38.800001 28.5,-62.100001c0.7,-12 -0.8,-29.5 -3.2,-36.600001c-11.5,-34.500001 -39.100001,-60.800001 -72.500001,-68.900001c-9.3,-2.3 -35.600001,-2.9 -43.900001,-1zm35.600001,15.4c17.6,3.6 31.9,11.6 44.200001,24.5c43.700001,46.000001 22.3,121.400002 -39.400001,138.700002c-11.3,3.2 -31.5,3.2 -42.800001,0c-14.8,-4.1 -26.9,-11.3 -38.100001,-22.5c-8.2,-8.2 -13.7,-16.2 -17.7,-25.7c-5.2,-12.4 -6.2,-18.2 -6.2,-33.900001c0.1,-13.4 0.3,-15.2 3.2,-24c9.3,-28.3 32.5,-49.900001 61.000001,-57.000001c10.1,-2.5 24.1,-2.5 35.800001,-0.1z"/>
 <path fill="none" id="svg_4" d="m260.500005,115.499996c-35.300001,7.8 -61.600001,36.500001 -65.700001,71.600001c-4.2,35.600001 14.1,68.700001 46.500001,84.400001c13.5,6.5 19.8,7.8 35.700001,7.8c15.9,0 22.2,-1.3 35.700001,-7.8c20.6,-10 35.800001,-27.2 43.400001,-49.200001c1.1,-3.2 2.5,-10.2 3.1,-15.6c4.4,-37.500001 -16.8,-72.900001 -52.200001,-87.000001c-13.5,-5.3 -33.3,-7.1 -46.500001,-4.2zm62.500001,38.000001c0,0.6 -3.4,4.4 -7.6,8.5c-4.1,4.1 -9.4,9.7 -11.6,12.3l-4.1,4.9l11.6,11.6c6.5,6.5 11.7,12.2 11.7,12.7c0,1.4 -11.3,12.5 -12.7,12.5c-1.1,0 -35.500001,-35.200001 -36.100001,-36.800001c-0.1,-0.5 7.9,-9.5 17.9,-20.1l18.2,-19.2l6.4,6.3c3.4,3.4 6.3,6.7 6.3,7.3zm-58.100001,44.200001l18,18.8l-18.9,19l-19,19l-6.7,-6.8l-6.8,-6.7l12.4,-12.4l12.3,-12.3l-12.2,-12.8l-12.1,-12.8l5.8,-5.9c3.1,-3.2 6.5,-5.8 7.4,-5.8c1,0 9.8,8.4 19.8,18.7z"/>
 <path fill="${color}" id="svg_5" d="m304.000005,147.999996c-3,3.3 -10.9,11.6 -17.4,18.5l-12,12.6l18,18l17.9,17.9l5.7,-5.7l5.8,-5.8l-12.2,-12.2l-12.2,-12.2l12.4,-12.6l12.3,-12.6l-5.9,-6c-3.2,-3.2 -6.1,-5.9 -6.4,-5.9c-0.3,0 -3,2.7 -6,6z"/>
 <path fill="${color}" id="svg_6" d="m239.000004,186.499997l-5.3,5.4l10.9,11.6c6,6.4 11.3,12.3 11.9,13.1c0.8,1.1 -1.8,4.1 -11,13.4l-12,12l5.8,5.7l5.7,5.8l18.2,-18.3l18.3,-18.2l-18,-18c-9.9,-9.9 -18.2,-18 -18.5,-18c-0.3,0 -3,2.5 -6,5.5z"/>
</g>
</svg>`
