/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import * as R from 'ramda'
import { component } from 'framework-x'
import { Div, Img, I } from '../utils/styled-utils'
import { AbstractDialogFactory } from '../components/dialogs'
import { brokerTheme } from '../configuration/subs'
import { evt } from '../eventTypes'
import { ConnectIcon } from '../icons/Laptop'
import { PowerOnIcon } from '../icons/PowerOn'
import { createSub } from '../utils'
import {
  SearchDropdown,
  DropdownItem,
  SecondaryButton,
  PrimaryButton,
  ActionButton
} from '../views/shared'
import { dispatch } from '../store'
import { getDesktops } from './calcs'
import { DEFAULT_DISPLAY_OPTION, DESKTOP_STATUS } from './static'
import theme from '../views/theme'
import win7 from '../assets/Windows 7.svg'
import win8 from '../assets/Windows 8.svg'
import win10 from '../assets/Windows 10.svg'
import win2k12 from '../assets/Windows Server 2012.svg'
import win2k16 from '../assets/Windows Server 2016.svg'
import win2k19 from '../assets/Windows Server 2019.svg'
import linux from '../assets/Linux.svg'
import { html5logo, rdpclientLogo } from '../assets/logos'

const screenResolutionOptionValues = () => [
  '640x480',
  '640x480',
  '800x600',
  '1024x768',
  '1280x720',
  '1280x800',
  '1280x960',
  '1280x1024',
  '1366x768',
  '1440x900',
  '1440x1050',
  '1600x900',
  '1600x1000',
  '1600x1200',
  '1920x1080',
  '1920x1200'
]
const screenResolutionOptions = values =>
  R.map(x => ({ label: x, value: x }), values)
const displayMethodOptions = [
  {
    value: 'multimon',
    label: 'Multi Monitor (Full Screen)'
  },
  {
    value: 'fullscreen',
    label: 'Fullscreen'
  }
]

const displayOptions = R.reduce(
  (a, { label, value }) => R.assoc(value, label, a),
  {},
  displayMethodOptions.concat(
    screenResolutionOptions(screenResolutionOptionValues())
  )
)

const selectedDisplayOption = R.pathOr(DEFAULT_DISPLAY_OPTION, [
  'settings',
  'display'
])

const Settings = component(
  'Settings',
  createSub({
    selectedDisplayOption
  }),
  ({ selectedDisplayOption }) => {
    return (
      <AbstractDialogFactory
        windowCss={{ width: 640, minHeight: 200, color: theme.dark }}
        buttonConfig={{
          rightButton: {
            label: 'Save',
            dispatchEvent: evt.SAVE_EDIT_SETTINGS
          },
          leftButton: {
            label: 'Return',
            css: {
              color: theme.dark,
              backgroundColor: theme.darkerGray,
              fontWeight: theme.fontWeightSemiBold,
              transition: 'background 0.25s',
              '&:hover:not([disabled])': {
                backgroundColor: '#dae3e7'
              }
            },
            dispatchEvent: evt.SETTINGS_CLOSE
          }
        }}
        showCloseX={false}
      >
        <Div>
          <Div
            css={{
              paddingTop: 40,
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: 20
            }}
          >
            <Div
              css={{
                fontSize: theme.fontSizeVeryLarge,
                fontWeight: theme.fontWeightBold,
                paddingBottom: 15
              }}
            >
              Settings
            </Div>

            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: 30
              }}
            >
              <Div css={{ maxWidth: 240 }}>
                <Div
                  css={{
                    fontSize: theme.fontSizeMedium,
                    fontWeight: theme.fontWeightRegular
                  }}
                >
                  Display Settings
                </Div>
                <Div
                  css={{
                    fontWeight: theme.fontWeightRegular,
                    fontSize: theme.fontSizeVerySmall
                  }}
                >
                  Sets the display resolution in the remote console.
                </Div>
              </Div>

              <Div css={{ maxWidth: 360, width: 1000 }}>
                <SearchDropdown
                  autoFocus={true}
                  buttonText={R.prop(selectedDisplayOption, displayOptions)}
                >
                  {Object.entries(displayOptions).map(([value, label], i) => {
                    return (
                      <DropdownItem
                        key={i}
                        onClick={() =>
                          dispatch(evt.CHANGE_VALUE, [
                            ['settings', 'display'],
                            value
                          ])
                        }
                      >
                        {label}
                      </DropdownItem>
                    )
                  })}
                </SearchDropdown>
              </Div>
            </Div>
          </Div>
        </Div>
      </AbstractDialogFactory>
    )
  }
)

const osToLogo = {
  win7,
  win8,
  win10,
  win2k12,
  win2k16,
  win2k19,
  linux
}

const OSLogo = ({ os }) => {
  return (
    <Div
      css={{
        display: 'flex',
        borderBottom: `1px solid #EAEFF1`,
        paddingBottom: 20,
        marginBottom: 12,
        marginLeft: 20,
        marginRight: 20
      }}
    >
      <Img css={{ marginLeft: 12 }} src={R.prop(os, osToLogo)} />
    </Div>
  )
}

const desktopStatusLabel = status => {
  return status.charAt(0).toUpperCase() + status.slice(1)
}

const Status = ({ guestName, status }) => {
  const text = guestName
    ? `${guestName} (${desktopStatusLabel(status)})`
    : desktopStatusLabel(status)
  return (
    <Div
      css={{
        fontWeight: theme.fontWeightMedium,
        fontSize: 13,
        marginBottom: 12
      }}
    >
      {text}
    </Div>
  )
}

const ReleaseButton = component('releaseButton', opts => {
  return <PrimaryButton {...opts}>Release</PrimaryButton>
})

const ActionReleaseButton = component('actionreleaseButton', opts => {
  return <ActionButton {...opts}>Release</ActionButton>
})

const Actions = ({
  guestName,
  poolId,
  hostid,
  status,
  isAssignmentLoading,
  isPoweringOn,
  pendingConnect,
  pendingClientConnect,
  hideRelease,
  type,
  enableHtml5,
  enableRdp,
  popup,
  brokerTheme
}) => {
  const isReleasing = status === DESKTOP_STATUS.RELEASING

  let buttons = 0
  if (enableHtml5) buttons++
  if (enableRdp) buttons++
  if (type === 'vdi' && !hideRelease && status !== 'unassigned') buttons++
  const width = Math.floor(266 / buttons)
  // console.log({ buttons, width })

  const connectButtons = () => {
    return (
      <Div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          marginLeft: -8,
          marginRight: -8
        }}
      >
        <Div
          css={{
            display: 'flex',
            justifyContent: !hideRelease ? 'space-between' : 'flex-start',
            width: '100%'
          }}
        >
          {enableRdp === true && (
            <ActionButton
              css={{
                marginRight: 4,
                width
              }}
              busy={isAssignmentLoading || pendingConnect}
              disabled={isReleasing}
              onClick={
                status === DESKTOP_STATUS.READY ||
                status === DESKTOP_STATUS.CONNECTED
                  ? () => {
                      return (
                        !isAssignmentLoading &&
                        dispatch(evt.CONNECT_DESKTOP, {
                          guestName,
                          poolId,
                          hostid
                        })
                      )
                    }
                  : () =>
                      !isAssignmentLoading &&
                      dispatch(evt.REQUEST_DESKTOP, {
                        guestName,
                        poolId,
                        hostid,
                        connectionType: 'rdp'
                      })
              }
            >
              <Img
                css={{ marginRight: 4, width: 20 }}
                src={`data:image/svg+xml;base64,${btoa(
                  rdpclientLogo(brokerTheme.buttonTextColor || '#2A2A2A')
                )}`}
              />
              RDP
            </ActionButton>
          )}
          {enableHtml5 === true && (
            <ActionButton
              css={{
                marginRight: 4,
                width
              }}
              busy={isAssignmentLoading || pendingClientConnect}
              disabled={isReleasing}
              onClick={() => {
                if (!isAssignmentLoading) {
                  dispatch(evt.REQUEST_DESKTOP, {
                    guestName,
                    poolId,
                    hostid,
                    connectionType: 'web'
                  })
                }
                if (!popup || popup.parent === null) {
                  popup = window.open(
                    guestName === undefined
                      ? `${window.location.origin}/client/connect?poolId=${poolId}`
                      : `${window.location.origin}/client/connect?guest=${guestName}`,
                    '_blank',
                    'toolbar=0,location=0,menubar=0'
                  )
                } else {
                  popup.location.href =
                    guestName === undefined
                      ? `${window.location.origin}/client/connect?poolId=${poolId}`
                      : `${window.location.origin}/client/connect?guest=${guestName}`
                }
                dispatch(evt.CHANGE_VALUE, [['popup'], popup])
              }}
            >
              <Img
                css={{ marginRight: 6, height: 22 }}
                src={`data:image/svg+xml;base64,${btoa(
                  html5logo(brokerTheme.buttonTextColor || '#2A2A2A')
                )}`}
              />
              HTML5
            </ActionButton>
          )}
          {!hideRelease &&
            DESKTOP_STATUS.UNASSIGNED !== status &&
            (!type || type === 'vdi') && (
              <ActionReleaseButton
                busy={isAssignmentLoading || isReleasing}
                css={{
                  marginRight: 4,
                  width
                }}
                onClick={() =>
                  dispatch(evt.RELEASE_DESKTOP, { guestName, poolId })
                }
              />
            )}
        </Div>
      </Div>
    )
  }

  const releaseOnly = (title, isDisabled, msg = 'Contact Support') => {
    let busy = isReleasing || isDisabled || status === DESKTOP_STATUS.PREPARE
    return (
      <Div title={title}>
        {!hideRelease && (!type || type === 'vdi') ? (
          <ReleaseButton
            busy={busy}
            disabled={isDisabled}
            css={{
              marginLeft: busy ? -4 : 0,
              width: 258
            }}
          />
        ) : (
          <PrimaryButton
            disabled={true}
            busy={isReleasing || isDisabled}
            css={{
              marginLeft: isAssignmentLoading || isReleasing ? -4 : 0,
              width: 258
            }}
          >
            {msg}
          </PrimaryButton>
        )}
      </Div>
    )
  }
  const powerAction = title => (
    <Div
      css={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Div title={title}>
        <PrimaryButton
          css={
            hideRelease || isPoweringOn
              ? {
                  marginRight: 4,
                  marginLeft: isAssignmentLoading || isReleasing ? -4 : 0,
                  width: 258
                }
              : {
                  marginRight: 4,
                  marginLeft: isAssignmentLoading || isReleasing ? -4 : 0
                }
          }
          busy={isPoweringOn}
          disabled={!!title || isReleasing}
          onClick={
            !title
              ? () =>
                  dispatch(evt.POWERON_DESKTOP, {
                    guestName,
                    poolId: poolId || 'external'
                  })
              : () => false
          }
        >
          <PowerOnIcon css={{ marginRight: 12 }} />
          Power On
        </PrimaryButton>
      </Div>
      {!hideRelease && !isPoweringOn && (
        <ReleaseButton
          busy={isReleasing}
          disabled={isAssignmentLoading}
          onClick={() => dispatch(evt.RELEASE_DESKTOP, { guestName, poolId })}
        />
      )}
    </Div>
  )
  switch (status) {
    case DESKTOP_STATUS.UNASSIGNED:
      return (
        type === 'vdi' &&
        connectButtons(DESKTOP_STATUS.UNASSIGNED, enableHtml5, enableRdp)
      )
    case DESKTOP_STATUS.RELEASING:
      return (
        <Div
          title="Currently assigned guest is being released, please wait"
          css={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <PrimaryButton
            css={
              hideRelease ? { marginRight: 4, width: 258 } : { marginRight: 4 }
            }
            busy={true}
          >
            <ConnectIcon css={{ marginRight: 12 }} />
            Request Desktop
          </PrimaryButton>
        </Div>
      )
    case DESKTOP_STATUS.FAILED:
      return releaseOnly(
        'Failed to inject userVolume, please release guest, and try again'
      )
    case DESKTOP_STATUS.PREPARE:
      return releaseOnly(
        'Please wait while system is preparing the guest',
        true
      )
    case DESKTOP_STATUS.READY:
    case DESKTOP_STATUS.CONNECTED:
      return connectButtons(DESKTOP_STATUS.READY, enableHtml5, enableRdp)
    case DESKTOP_STATUS.STOPPED:
      return powerAction()
    case DESKTOP_STATUS.STARTING:
      return releaseOnly('Guest is starting up', true, 'Powering On')
    default:
      return releaseOnly('Guest is not available')
  }
}

// TODO combine NonVdiTile and this into one - need to handle field differences
const Tile = component(
  'DesktopTile',
  ({
    os,
    guestPoolId: poolId,
    guestPoolName: poolName,
    guestName,
    hostid,
    status,
    isAssignmentLoading,
    pendingConnect,
    pendingClientConnect,
    isPoweringOn,
    hideRelease,
    type,
    enableHtml5,
    enableRdp = true,
    brokerTheme,
    description
  }) => {
    return status === 'unassigned' && type !== 'vdi' ? null : (
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          border: `1px solid #EAEFF1`,
          justifyContent: 'space-between'
        }}
      >
        <Div
          css={{
            background: '#fff',
            color: '#2a2a2a',
            width: 292,
            maxHeight: 300,
            padding: '14px 16px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <OSLogo os={os} />
          <Div
            css={{
              fontSize: 17,
              fontWeight: theme.fontWeightBold,
              height: 40
            }}
          >
            {type === 'external' ? guestName : poolName}
          </Div>
          <Status {...{ guestName, status, type }} />
          {!!description && description.length > 0 && (
            <Div css={{ maxHeight: 100, overflow: 'auto', marginTop: 10 }}>
              <Div>{description}</Div>
            </Div>
          )}
        </Div>
        <Div
          css={{
            padding: '14px 16px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Actions
            {...{
              guestName,
              poolId,
              hostid,
              status,
              isAssignmentLoading,
              pendingConnect,
              pendingClientConnect,
              isPoweringOn,
              hideRelease,
              type,
              enableHtml5,
              enableRdp,
              brokerTheme
            }}
          />
        </Div>
      </Div>
    )
  }
)

// TODO combine Tile and this into one - need to handle field differences
const NonVdiTile = component(
  'nonVdiDesktopTile',
  ({
    os,
    guestPoolId: poolId,
    guestPoolName: poolName,
    guestName,
    hostid,
    status,
    isAssignmentLoading,
    pendingConnect,
    pendingClientConnect,
    isPoweringOn,
    hideRelease,
    type,
    enableHtml5,
    enableRdp = true,
    popup,
    brokerTheme,
    description
  }) => {
    return status === 'unassigned' ? null : (
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          border: `1px solid #EAEFF1`,
          justifyContent: 'space-between'
        }}
      >
        <Div
          css={{
            background: '#fff',
            color: '#2a2a2a',
            width: 292,
            maxHeight: 500,
            padding: '14px 16px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <OSLogo os={os} />
          <Div
            css={{
              fontSize: 17,
              fontWeight: theme.fontWeightBold,
              height: 40
            }}
          >
            {guestName}
          </Div>
          <Status {...{ guestName: poolName, status, type }} />
          {!!description && description.length > 0 && (
            <Div css={{ maxHeight: 100, overflow: 'auto', marginTop: 10 }}>
              <Div>{description}</Div>
            </Div>
          )}
        </Div>
        <Div
          css={{
            padding: '14px 16px',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Actions
            {...{
              guestName,
              poolId,
              poolName,
              hostid,
              status,
              isAssignmentLoading,
              pendingConnect,
              pendingClientConnect,
              isPoweringOn,
              hideRelease,
              type,
              enableHtml5,
              enableRdp,
              popup,
              brokerTheme
            }}
          />
        </Div>
      </Div>
    )
  }
)

const isEditingSettings = R.path(['settings', 'isEditing'])
export const TableSquareContainer = styled.div({
  display: 'grid',
  gridGap: 12,
  gridTemplateColumns: 'repeat(auto-fill, 292px)',
  marginTop: '30px',
  marginBottom: '30px'
})
const FullPageManagementLayout = styled.div({
  marginLeft: 20,
  marginRight: 20,
  display: 'grid',
  gridGap: '1rem',
  overflow: 'visible',
  gridTemplateAreas: `". main ."`,
  gridTemplateColumns: '1fr 1204px 1fr',

  '@media screen and (max-width: 1400px)': {
    gridTemplateColumns: '1fr 903px 1fr'
  },
  '@media screen and (max-width: 1000px)': {
    gridTemplateColumns: '1fr 602px 1fr'
  },
  '@media screen and (max-width: 700px)': {
    gridTemplateColumns: '1fr 301px 1fr'
  }
})
const ActionForm = styled.div({
  gridArea: 'main',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  /*To show alerts at the edge hack*/
  paddingRight: 30,
  marginRight: -30
})

export const Guests = component(
  'Guests',
  createSub({
    username: R.path(['user', 'user', 'username']),
    desktops: getDesktops,
    isEditingSettings,
    brokerTheme,
    hideRelease: R.pathOr(false, ['configuration', 'hideRelease'])
  }),
  ({ username, brokerTheme, desktops, isEditingSettings, hideRelease }) => {
    return (
      <Div>
        <FullPageManagementLayout>
          <ActionForm>
            <Div
              css={{
                margin: '0 auto',
                paddingTop: '10px',
                height: '48px',
                fontWeight: theme.fontWeightBold,
                fontSize: 24
              }}
            >
              Logged in as {username}
            </Div>
            <Div
              css={{
                display: 'flex',
                marginBottom: 20,
                justifyContent: 'space-between'
              }}
            >
              <Div css={{ display: 'flex', margin: 'auto 0' }}>
                <Div css={{ fontWeight: theme.fontWeightMedium }}>
                  GUEST ASSIGNMENTS
                </Div>
                <I css={{ paddingLeft: 12 }}>{desktops.length} total</I>
              </Div>
              <SecondaryButton
                onClick={() => dispatch(evt.START_EDIT_SETTINGS)}
                backgroundColor="#FAFAFA"
                textColor="#2A2A2A"
                css={{
                  display: 'flex',
                  justifyContent: 'end'
                }}
              >
                Settings
              </SecondaryButton>
            </Div>

            <TableSquareContainer>
              {desktops
                .filter(desktop => desktop.type === 'vdi')
                .map((vdiPool, i) => (
                  <Tile
                    key={i}
                    {...vdiPool}
                    hideRelease={hideRelease}
                    brokerTheme={brokerTheme}
                  />
                ))}
              {/* standalone and external separated to ensure SA guests display before external guests */}
              {desktops
                .filter(
                  desktop =>
                    desktop && desktop.type && desktop.type === 'standalone'
                )
                .map((nonVdiPool, i) => (
                  <NonVdiTile
                    key={i}
                    {...nonVdiPool}
                    hideRelease={true}
                    brokerTheme={brokerTheme}
                  />
                ))}
              {desktops
                .filter(
                  desktop =>
                    desktop && desktop.type && desktop.type === 'external'
                )
                .map((nonVdiPool, i) => (
                  <NonVdiTile
                    key={i}
                    {...nonVdiPool}
                    hideRelease={true}
                    brokerTheme={brokerTheme}
                  />
                ))}
            </TableSquareContainer>
          </ActionForm>
          {isEditingSettings && <Settings />}
        </FullPageManagementLayout>
      </Div>
    )
  }
)
