import React from 'react'

export const InputError = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-680.000000, -310.000000)">
        <g
          transform="translate(280.000000, 120.000000)"
          fill="#D05F5F"
          stroke="#D05F5F"
        >
          <g transform="translate(120.000000, 165.000000)">
            <rect x="280.5" y="25.5" width="32" height="32" />
          </g>
        </g>
        <path
          d="M695.878906,330.230469 C695.657551,330.230469 695.472006,330.1556 695.322266,330.005859 C695.172525,329.856119 695.097656,329.670574 695.097656,329.449219 L695.097656,319.78125 C695.097656,319.559895 695.172525,319.37435 695.322266,319.224609 C695.472006,319.074869 695.657551,319 695.878906,319 C696.100262,319 696.285807,319.074869 696.435547,319.224609 C696.585287,319.37435 696.660156,319.559895 696.660156,319.78125 L696.660156,329.449219 C696.660156,329.670574 696.585287,329.856119 696.435547,330.005859 C696.285807,330.1556 696.100262,330.230469 695.878906,330.230469 Z M695,332.378906 C695,332.131509 695.084635,331.923178 695.253906,331.753906 C695.423178,331.584635 695.631509,331.5 695.878906,331.5 C696.126303,331.5 696.334635,331.584635 696.503906,331.753906 C696.673178,331.923178 696.757812,332.131509 696.757812,332.378906 C696.757812,332.626303 696.673178,332.834635 696.503906,333.003906 C696.334635,333.173178 696.126303,333.257812 695.878906,333.257812 C695.631509,333.257812 695.423178,333.173178 695.253906,333.003906 C695.084635,332.834635 695,332.626303 695,332.378906 Z"
          fill="#F7F9FA"
        />
      </g>
    </g>
  </svg>
)
