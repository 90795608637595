const tokenKey = 'hive-io-broker.token'

export const getLocalStorageToken = () => {
  return window.localStorage.getItem(tokenKey)
}

export const setLocalStorageToken = token => {
  window.localStorage.setItem(tokenKey, token)
}

export const deleteLocalStorageToken = () => {
  window.localStorage.removeItem(tokenKey)
}

// Display
const displayKey = 'hive-io.displayPreference'

export const getLocalStorageDisplay = () => {
  return window.localStorage.getItem(displayKey)
}

export const setLocalStorageDisplay = display => {
  window.localStorage.setItem(displayKey, display)
}

export const deleteLocalStorageDisplay = () => {
  window.localStorage.removeItem(displayKey)
}
