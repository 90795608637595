import * as R from 'ramda'
import { derive } from 'framework-x'
import { DESKTOP_STATUS } from './static'

const desktopsMapRaw = R.pathOr({}, ['data', 'desktops'])
const desktopsRaw = derive([desktopsMapRaw], R.values)

export const desktopStatus = desktop => {
  if (desktop && desktop.guestState) {
    if (desktop.pendingRelease) {
      return DESKTOP_STATUS.RELEASING
    }
    if (desktop.pendingAssignment === true) {
      return DESKTOP_STATUS.PREPARE
    }
    if (!!R.path(['userVolume', 'state'], desktop)) {
      if (desktop.userVolume.state !== 'ready') {
        if (desktop.userVolume.state === 'error') {
          return DESKTOP_STATUS.FAILED
        }
        return DESKTOP_STATUS.PREPARE
      }
    }
    switch (desktop.guestState) {
      case 'ready':
      case 'active':
        if (desktop.sessionStatus === 'connected') {
          return DESKTOP_STATUS.CONNECTED
        }
        return DESKTOP_STATUS.READY
      case 'stopped':
        return DESKTOP_STATUS.STOPPED
      case 'prepare':
      case 'running':
      case 'starting':
      case 'start':
        return DESKTOP_STATUS.STARTING
      default:
        return DESKTOP_STATUS.UNAVAILABLE
    }
  }
  return DESKTOP_STATUS.UNASSIGNED
}

export const getPopup = R.path(['popup'])

export const getDesktops = derive(
  [desktopsRaw, getPopup],
  (desktops, popup) => {
    return R.map(
      desktop =>
        R.merge(desktop, {
          status: desktopStatus(desktop),
          isAssignmentLoading: desktop.pendingAssignment,
          isPoweringOn:
            desktop.pendingStart && desktop.guestState === 'stopped',
          popup
        }),
      desktops
    )
  }
)
