import { Svg } from '../utils/styled-utils'
import React from 'react'

export const SmallSpinner = ({ ...css }) => {
  return (
    <Svg {...css} width="12px" height="12px" viewBox="0 0 14 12">
      <defs>
        <linearGradient
          x1="50.3346784%"
          y1="33.6318736%"
          x2="59.1496938%"
          y2="84.4413596%"
          id="hive-small-spinner-gradient"
        >
          <stop stopColor="#7F898B" offset="0%" />
          <stop stopColor="#7F898B" stopOpacity="0" offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
        <circle
          id={'hive-spinner-small'}
          stroke="url(#hive-small-spinner-gradient)"
          cx="7"
          cy="7"
          r="6"
        />
      </g>
    </Svg>
  )
}
