import { component } from 'framework-x'
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Div, Img } from '../utils/styled-utils'
import styled from '@emotion/styled'
import * as R from 'ramda'
import React from 'react'
import { AbstractDialogFactory } from '../components/dialogs'
import { brokerTheme } from '../configuration/subs'
import { evt } from '../eventTypes'
import { hexagons } from '../icons/Hexagons'
import { dispatch } from '../store'
import { getRealmIdLabelPairs } from '../subs/authCalcs'
import { childPath, createSub, derive } from '../utils'
import {
  SearchDropdown,
  DropdownItem,
  PrimaryButton,
  TextInputWithErrors
} from '../views/shared'
import { getLoginForm } from '../subs'
import theme from '../views/theme'
import { markdown } from 'markdown'
import watermarkData from '../assets/hive-logo-mono-nooutline.svg'

let loginAction

const Disclaimer = component(
  'Disclaimer',
  createSub({ text: R.path(['configuration', 'disclaimer']) }),
  ({ text }) => {
    return (
      <AbstractDialogFactory
        windowCss={{ width: 540, minHeight: 200 }}
        buttonConfig={{
          rightButton: {
            label: 'Accept',
            dispatchEvent: evt.ACKNOWLEDGE_DISCLAIMER
          }
        }}
        showCloseX={false}
      >
        <Div>
          <Div
            css={{
              paddingTop: 40,
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: 20
            }}
          >
            <Div
              css={{
                fontSize: theme.fontSizeVeryLarge,
                fontWeight: theme.fontWeightBold,
                paddingBottom: 15
              }}
            >
              Disclaimer
            </Div>

            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: 30,
                maxHeight: 400,
                overflowY: 'auto'
              }}
            >
              {text && typeof text === 'string' && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: markdown.toHTML(text)
                  }}
                />
              )}
            </Div>
          </Div>
        </Div>
      </AbstractDialogFactory>
    )
  }
)

const Label = styled.div({
  fontWeight: theme.fontWeightMedium
})

const updateForm = (prop, value) =>
  dispatch(evt.CHANGE_VALUE, [childPath(['forms', 'login'], prop), value])
const clearError = prop =>
  dispatch(evt.CHANGE_VALUE, [
    childPath(['forms', 'login', 'errors'], prop),
    undefined
  ])

const field = ks => derive([getLoginForm], R.path(ks))
const errors = ks => derive([getLoginForm], R.path(['errors', ks]))

const Buttons = component(
  '',
  createSub({
    realms: R.path(['data', 'realms']),
    requesting: field(['requesting']),
    mfa: R.or(
      R.pathEq(['configuration', 'twoFormAuth', 'type'], 'mfa'),
      R.pathEq(['configuration', 'twoFormAuth', 'type'], 'azureMFA')
    ),
    authChallenge: R.path(['forms', 'login', 'state'])
  }),
  ({ realms, requesting, mfa, authChallenge, mfaOveride, ...props }) => {
    let mfaStatus = !authChallenge
      ? 'Waiting for 2 factor authentication response'
      : 'Waiting for 2 factor challange verification'
    if (mfa && !mfaOveride) {
      if (authChallenge) {
        loginAction = evt.MFA_TOKEN_REQUEST
      } else {
        loginAction = evt.MFA_AUTH_REQUEST
      }
    } else {
      loginAction = evt.LOGIN_REQUEST
    }
    return (
      <Div>
        <PrimaryButton
          css={{ width: '100%', marginBottom: 15 }}
          {...props}
          onClick={() => dispatch(loginAction)}
          busy={requesting}
          disabled={R.isEmpty(realms) || R.isNil(realms)}
        >
          Login
        </PrimaryButton>
        {mfa && requesting && (
          <Label css={{ textAlign: 'center' }}>{mfaStatus}</Label>
        )}
      </Div>
    )
  }
)

const Retry = component('', R.T, () => (
  <Div>
    <PrimaryButton
      css={{ width: '100%', marginBottom: 15 }}
      onClick={() => window.location.reload()}
    >
      Retry
    </PrimaryButton>
  </Div>
))

const Username = component(
  'login-username',
  createSub({
    value: field(['username']),
    realms: R.path(['data', 'realms']),
    requesting: field(['requesting']),
    errors: errors(['username'])
  }),
  ({ value, errors, realms, requesting, ...rest }) => {
    // console.log({ realms })
    return (
      <Div>
        <Label>Username</Label>
        <TextInputWithErrors
          {...rest}
          disabled={R.isEmpty(realms) || R.isNil(realms) || requesting}
          css={{ width: '100%' }}
          value={value || ''}
          errors={errors}
          onChange={e => {
            updateForm('username', e.target.value)
            if (!!errors) clearError('username')
          }}
          onKeyDown={e => (e.which === 13 ? dispatch(loginAction) : null)}
          autocomplete="username"
        />
      </Div>
    )
  }
)

const Password = component(
  'login-password',
  createSub({
    value: field(['password']),
    errors: errors(['password']),
    realms: R.path(['data', 'realms']),
    requesting: field(['requesting'])
  }),
  ({ value, errors, realms, requesting }) => {
    return (
      <Div css={{ paddingTop: 25 }}>
        <Label>Password</Label>
        <TextInputWithErrors
          id={'loginPassword'}
          disabled={R.isEmpty(realms) || R.isNil(realms) || requesting}
          type={'password'}
          value={value || ''}
          errors={errors}
          onChange={e => {
            updateForm('password', e.target.value)
            if (!!errors) clearError('password')
          }}
          onKeyDown={e => (e.which === 13 ? dispatch(loginAction) : null)}
        />
      </Div>
    )
  }
)

const Passcode = component(
  'login-passcode',
  createSub({
    value: field(['passcode']),
    errors: errors(['passcode']),
    realms: R.path(['data', 'realms']),
    requesting: field(['requesting'])
  }),
  ({ value, errors, realms, requesting }) => {
    return (
      <Div css={{ paddingTop: 25 }}>
        <Label>Password</Label>
        <TextInputWithErrors
          id={'loginPassword'}
          css={{
            width: '100%'
          }}
          disabled={R.isEmpty(realms) || R.isNil(realms) || requesting}
          type={'password'}
          value={value || ''}
          errors={errors}
          onChange={e => {
            updateForm('passcode', e.target.value)
            if (!!errors) clearError('passcode')
          }}
          onKeyDown={e => (e.which === 13 ? dispatch(loginAction) : null)}
        />
      </Div>
    )
  }
)

const Realm = component(
  'loginrealm',
  createSub({
    realms: R.pipe(getRealmIdLabelPairs, R.omit(['local'])),
    value: field(['realm']),
    requesting: field(['requesting'])
  }),
  ({ realms, value, requesting }) => {
    if (typeof value === 'undefined' && !R.isEmpty(R.keys(realms))) {
      updateForm('realm', realms[Object.keys(realms).shift()])
      updateForm('password', '')
      updateForm('username', '')
    }
    const select = value => () => {
      updateForm('realm', value)
    }
    return (
      <Div css={{ paddingTop: 25 }}>
        <Label>Realm</Label>
        <SearchDropdown
          disabled={!realms || requesting}
          buttonText={realms[value]}
          css={{
            width: '100%'
          }}
        >
          {Object.entries(realms).map(([value, label]) => (
            <DropdownItem key={value} onClick={select(value)}>
              {label}
            </DropdownItem>
          ))}
        </SearchDropdown>
      </Div>
    )
  }
)

const TwoFormAuth = component(
  'login-TwoFormAuth',
  createSub({
    value: field(['token']),
    errors: errors(['token']),
    realms: R.path(['data', 'realms']),
    requesting: field(['requesting'])
  }),
  ({ value, realms, errors, requesting }) => {
    return (
      <Div css={{ paddingTop: 25 }}>
        <Label>Token</Label>
        <TextInputWithErrors
          disabled={requesting || R.isEmpty(realms) || R.isNil(realms)}
          css={{ width: '100%' }}
          value={value || ''}
          errors={errors}
          onChange={e => {
            updateForm('token', e.target.value)
            if (!!errors) clearError('token')
          }}
          onKeyDown={e => (e.which === 13 ? dispatch(loginAction) : null)}
        />
      </Div>
    )
  }
)

const NextCode = component(
  'login-NextCode',
  createSub({
    value: field(['passcode']),
    errors: errors(['passcode']),
    realms: R.path(['data', 'realms']),
    requesting: field(['requesting'])
  }),
  ({ value, realms, errors, requesting, label }) => {
    return (
      <Div css={{ paddingTop: 25 }}>
        <Label>{label ? label : 'Next Code'}</Label>
        <TextInputWithErrors
          disabled={requesting || R.isEmpty(realms) || R.isNil(realms)}
          css={{ width: '100%' }}
          value={value || ''}
          errors={errors}
          onChange={e => {
            updateForm('passcode', e.target.value)
            if (!!errors) clearError('passcode')
          }}
          onKeyDown={e => (e.which === 13 ? dispatch(loginAction) : null)}
        />
      </Div>
    )
  }
)

const isDisabled = R.complement(R.path(['configuration', 'enabled']))
const isUnavailable = R.path(['configuration', 'unavailable'])
const disclaimerAcknowledged = R.path(['login', 'disclaimerAcknowledged'])
const disclaimer = R.path(['configuration', 'disclaimer'])
const onRemotePath = () => {
  let path = window.location.pathname.split('/')
  return R.any(x => x === 'remote', path)
}

export const Login = component(
  'Login',
  createSub({
    showDisclaimer: derive(
      [isDisabled, disclaimerAcknowledged, disclaimer],
      (disabled, acknowledged, disclaimer) => {
        if (disabled) {
          return false
        }
        if (acknowledged) {
          return false
        }
        if (!disclaimer || disclaimer === '') {
          return false
        }
        return true
      }
    ),
    username: R.pathOr('', ['forms', 'login', 'username']),
    configuration: R.path(['configuration']),
    brokerTheme,
    errors: R.pipe(getLoginForm, R.prop('errors')),
    isDisabled,
    isUnavailable,
    noPools: R.pathOr(false, ['configuration', 'noPools']),
    authChallenge: R.path(['forms', 'login', 'state']),
    authReplyMessage: R.path(['forms', 'login', 'replyMessage']),
    base64HexLogo: derive([brokerTheme], theme => {
      return btoa(hexagons(theme.mainColor))
    }),
    license: R.path(['settings', 'license']),
    hideWatermark: R.pathOr(false, ['configuration', 'hideWatermark'])
  }),
  ({
    brokerTheme,
    showDisclaimer,
    username,
    configuration,
    isDisabled,
    isUnavailable,
    noPools,
    errors,
    forgotPasswordSuccess,
    showForgotPassword,
    authChallenge,
    authReplyMessage,
    base64HexLogo,
    license,
    hideWatermark
  }) => {
    document.title = configuration.title
    const hideRealms = configuration.hideRealms || username.includes('@')
    let mfa = false
    let showToken = false
    let loginForm
    if (!!license && typeof license.type === 'undefined') {
      window.location = `https://${window.location.hostname}:8443/`
      return
    }
    if (R.pathOr(false, ['twoFormAuth', 'enabled'], configuration)) {
      showToken =
        onRemotePath() || R.path(['twoFormAuth', 'enforceLocal'], configuration)
      mfa = showToken && R.pathEq(['twoFormAuth', 'type'], 'mfa')(configuration)
    }
    if (mfa) {
      loginForm = !!authChallenge ? (
        <React.Fragment>
          <Div>
            <Div>
              <p>
                {!!authReplyMessage
                  ? authReplyMessage
                  : 'Enter the verification code to complete your authentication.'}
              </p>
            </Div>
            <NextCode label={!!authReplyMessage ? 'Response' : 'Next Code'} />
          </Div>

          <Buttons />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Div>
            {!!configuration.mfaLoginMessage && (
              <Div>
                <p>{configuration.mfaLoginMessage}</p>
              </Div>
            )}
            <Username autoFocus={true} />
            <Passcode />
          </Div>

          <Buttons />
        </React.Fragment>
      )
    } else {
      loginForm = (
        <React.Fragment>
          <Div>
            <Username autoFocus={true} />
            <Password />
            {showToken && <TwoFormAuth />}
            {!hideRealms && <Realm />}
          </Div>

          <Buttons mfaOveride={true} />
        </React.Fragment>
      )
    }
    const disabledMsg = isUnavailable
      ? 'Broker is unavailable.'
      : 'Broker is disabled.'
    const noPoolMsg = 'No desktops available.'
    return (
      <Div
        css={
          brokerTheme.bgImage
            ? {
                display: 'flex',
                height: '100vh',
                width: '100vw',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(data:image/png;base64,${brokerTheme.bgImage})`,
                backgroundSize: 'cover'
              }
            : {
                display: 'flex',
                height: '100vh',
                width: '100vw',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: brokerTheme.backgroundColor
              }
        }
      >
        {!hideWatermark && (
          <Div
            style={{
              position: 'fixed',
              bottom: 10,
              right: 20,
              mixBlendMode: 'exclusion',
              opacity:
                typeof brokerTheme.watermarkOpacity !== 'number'
                  ? 0.3
                  : brokerTheme.watermarkOpacity
            }}
          >
            <img src={watermarkData} alt="Powered By HiveIO" />
          </Div>
        )}
        <Div css={{ background: '#191F27' }} />

        <Div
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '100vw',
            // background: `url("${loginBackground}") no-repeat center center fixed`,
            backgroundSize: 'contain'
          }}
        >
          <Div
            css={{
              color: 'black',
              background: 'white',
              display: 'flex',
              justifyContent: 'space-between',
              width: '755px',
              height: '436px',
              boxShadow: '0 2px 4px rgba(0,0,0,50)'
            }}
          >
            <Div
              css={{
                width: '35%',
                backgroundImage: `url('data:image/svg+xml;base64,${base64HexLogo}')`,
                backgroundRepeat: 'no-repeat',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                // paddingBottom: 20
                padding: '40px 24px'
              }}
            >
              <Img
                css={{
                  height: 56,
                  width: '100%',
                  objectFit: 'contain'
                }}
                src={
                  !!brokerTheme.logo
                    ? 'data:image/png;base64,' + brokerTheme.logo
                    : null
                }
              />
            </Div>

            <Div
              css={{
                width: '65%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 60
              }}
            >
              <Div
                css={{
                  width: '80%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around'
                }}
              >
                {isDisabled || noPools ? (
                  <React.Fragment>
                    <Div
                      css={{
                        fontSize: 24,
                        fontWeight: theme.fontWeightMedium,
                        color: theme.dark
                      }}
                    >
                      {noPools ? noPoolMsg : disabledMsg}
                      <p />
                      Contact your administrator for assistance.
                    </Div>
                    {noPools && <Retry />}
                  </React.Fragment>
                ) : (
                  loginForm
                )}
              </Div>
            </Div>
          </Div>
        </Div>
        {showDisclaimer && <Disclaimer />}

        <Div css={{ background: '#191F27' }} />
      </Div>
    )
  }
)
