import * as R from 'ramda'
import { derive } from '../utils'

const loginFormPath = ['forms', 'login']
export const getLoginForm = R.path(loginFormPath)

export const getRealmMap = R.pathOr({}, ['data', 'realms'])
export const getRealms = derive(getRealmMap, R.values)

export const getRealmIdLabelPairs = derive(
  getRealms,
  R.pipe(
    R.sortBy(R.prop('name')),
    R.map(({ name }) => [name, name]),
    R.fromPairs
  )
)

export const getRealmLabelValues = derive([getRealms], realms => {
  return [{ label: 'Select realm...', value: undefined }].concat(
    R.pipe(
      R.sortBy(R.prop('name')),
      R.map(({ name }) => ({ label: name, value: name }))
    )(realms)
  )
})
