import styled from '@emotion/styled'

export const Div = styled.div(props => {
  return {
    css: props.css || {}
  }
})
export const Form = styled.form(props => {
  return {
    css: props.css || {}
  }
})
export const Img = styled.img(props => {
  return {
    css: props.css || {}
  }
})
export const Span = styled.span(props => {
  return {
    css: props.css || {}
  }
})
export const Svg = styled.svg(props => {
  return {
    css: props.css || {}
  }
})
export const Input = styled.input(props => {
  return {
    css: props.css || {}
  }
})
export const Button = styled.button(props => {
  return {
    css: props.css || {}
  }
})
export const Text = styled.text(props => {
  return {
    css: props.css || {}
  }
})
export const I = styled.i(props => {
  return {
    css: props.css || {}
  }
})
