import React from 'react'

export const TooltipEnd = () => (
  <svg width="11px" height="28px" viewBox="0 2 11 28.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1166.000000, -161.000000)">
        <g transform="translate(983.000000, 161.000000)" fill="#2A2A2A">
          <g>
            <path d="M17.9117895,1.73003755e-15 L184,0 C189.522847,-1.57303525e-14 194,4.4771525 194,10 L194,22 C194,27.5228475 189.522847,32 184,32 L17.9117895,32 C14.9263558,32 12.0970588,30.6661249 10.1974128,28.3630489 L0,16 L10.1974128,3.63695113 C12.0970588,1.33387506 14.9263558,5.4841528e-16 17.9117895,0 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
