import { dispatch } from '../store'
import { evt } from '../eventTypes'
import { apiSocketEvents as apiEvents } from '../apiSocketEvents'
import { regEventFx } from '../store'
import { assocPath } from 'ramda'
import * as R from 'ramda'
import { isEmpty, updateIn } from '../util'
import { desktopStatus } from './calcs'
import { DESKTOP_STATUS } from './static'

const update = (dataKey, collection, idProperty) => row =>
  R.assocPath([dataKey, collection, R.prop(idProperty, row)], row)

const rethinkTableToClientCollectionMap = {
  guest: ['guests', 'name']
}

const makeTableUpdaters = dataKey =>
  R.map(
    ([collection, idProp]) => update(dataKey, collection, idProp),
    rethinkTableToClientCollectionMap
  )

const subscriptionTable = (db, hash) =>
  R.path(['subscriptions', hash, 'table'], db)

const eventType = change => {
  const { new_val: value } = change
  return value === null || value === undefined
    ? evt.GUEST_REMOVED
    : evt.GUEST_CHANGE // upserts / overwrites entity with assoc
}

const entityPath = (table, change) => {
  if (!rethinkTableToClientCollectionMap[table]) {
    throw new Error('Unhandled table change: ' + table)
  }
  const [collection, idProp] = rethinkTableToClientCollectionMap[table]
  const { old_val: oldValue, new_val: value } = change
  const id = R.prop(idProp, value) || R.prop(idProp, oldValue)

  return [collection, id]
}

const changeData = (table, change) => {
  return [
    eventType(change),
    entityPath(table, change),
    R.prop('new_val', change)
  ]
}

const makeTableChangeRf = dataKey => (acc, [event, ks, v]) => {
  if (event === evt.GUEST_REMOVED) {
    return R.dissocPath([dataKey].concat(ks), acc)
  }
  return R.assocPath([dataKey].concat(ks), v, acc)
}

/* Subscribe to all the tables */
regEventFx(evt.API_SOCKET_CONNECTED, ({ db }) => {
  const username = R.path(['user', 'user', 'username'], db)
  const querySubscription = {
    table: 'guest',
    filter: { username },
    pluck: [
      'name',
      'hostid',
      'guestState',
      'username',
      'poolId',
      'interfaces',
      'publishedIp',
      'userVolume',
      'SessionInfo',
      'userSessionInfo'
    ],
    includeInitial: true
  }
  const querySubscriptionEvent = [
    apiEvents.LISTEN_FOR_QUERY_CHANGES,
    querySubscription,
    (...a) => dispatch(evt.QUERY_SUBSCRIPTION_REGISTERED, a)
  ]
  return [['emitApi', querySubscriptionEvent]]
})

regEventFx(
  evt.QUERY_SUBSCRIPTION_REGISTERED,
  ({ db }, _, [hash, subscription]) => {
    return {
      db: R.assocPath(['subscriptions', hash], subscription)
    }
  }
)

regEventFx(apiEvents.QUERY_CHANGE, ({ db }, __, m) => {
  const { table: subscriptionHash, change = {} } = m
  const table = subscriptionTable(db, subscriptionHash) || subscriptionHash
  const [collection, idProp] = rethinkTableToClientCollectionMap[table]

  const dataKey = 'data'
  if (Array.isArray(change)) {
    const changes = change

    const db$ = R.pipe(
      R.map(change => changeData(table, change)),
      R.reduce(makeTableChangeRf(dataKey), db)
    )(changes)

    return {
      db: db$,
      dispatch: [
        evt.COLLECTION_INITIAL_VALUE,
        [collection, R.path([dataKey, collection], db$)]
      ]
    }
  }

  const { old_val: oldValue, new_val: value } = change
  if (!rethinkTableToClientCollectionMap[table]) {
    throw new Error('Unhandled table change: ' + table)
  }

  if (value === null || value === undefined) {
    //remove!
    return {
      db: R.dissocPath([dataKey, collection, R.prop(idProp, oldValue)]),
      dispatch: [
        evt.GUEST_REMOVE,
        {
          collection,
          id: R.prop(idProp, oldValue),
          oldValue
        }
      ]
    }
  } else {
    const tableUpdaters = makeTableUpdaters(dataKey)
    return {
      db: tableUpdaters[table](value),
      dispatch: [
        evt.GUEST_CHANGE,
        {
          collection,
          id: R.prop(idProp, value),
          value
        }
      ]
    }
  }
})

regEventFx(apiEvents.QUERY_INITIAL, ({ db }, __, [hash, data]) => {
  const table = subscriptionTable(db, hash)
  if (!rethinkTableToClientCollectionMap[table]) {
    throw new Error('Unknown table: ' + table)
  }

  const dataKey = 'data'

  const [coll, idProp] = rethinkTableToClientCollectionMap[table]

  const m = R.indexBy(R.prop(idProp), data)
  const keyFn = R.prop(idProp)

  return {
    db: R.reduce(
      (acc, m) => R.assocPath([dataKey, coll, keyFn(m)], m)(acc),
      db,
      data
    ),
    dispatch: [evt.COLLECTION_INITIAL_VALUE, [coll, m]]
  }
})

regEventFx(evt.GUEST_CHANGE, ({ db }, _, { collection, id, value }) => {
  let popup = R.path(['popup'], db)
  const guest = value,
    poolId = guest.poolId,
    desktopRecord = R.path(['data', 'desktops', poolId], db)
  let ip,
    sessionStatus = guest.userSessionState || 'disconnected'

  if (!sessionStatus && typeof guest.SessionInfo === 'object') {
    sessionStatus = guest.SessionInfo.sessionsStatus || 'disconnected'
  }
  if (guest.publishedIp && guest.interfaces) {
    const matchedInterface = guest.interfaces.filter(
      ifs => ifs.macAddress.toLowerCase() === guest.publishedIp.toLowerCase()
    )
    if (matchedInterface.length > 0) {
      ip = matchedInterface[0].ipAddress
    }
  }
  let next = {},
    desktop = Object.assign({}, desktopRecord, {
      guestName: guest.name,
      hostid: guest.hostid,
      ip,
      guestState: guest.guestState,
      userVolume: guest.userVolume,
      sessionStatus: sessionStatus || 'disconnected',
      pendingConnect: desktopRecord.pendingConnect || !desktopRecord.guestName
    })
  //console.log({ poolId, guest, desktop }, 'GUEST_CHANGE')
  if (desktop.pendingConnect && ip) {
    const status = desktopStatus(desktop)
    if ([DESKTOP_STATUS.READY, DESKTOP_STATUS.CONNECTED].includes(status)) {
      delete desktop.pendingConnect
      next.dispatchN2 = [
        [
          evt.API_REQUEST,
          {
            method: 'post',
            url: '/userSession/mark',
            data: {
              type: 'guestAssignmentEnd',
              timestamp: new Date(),
              username: R.path(['user', 'user', 'username'])(db),
              realm: R.path(['user', 'realm'])(db),
              guestName: desktop.guestName,
              hostid: desktop.hostid
            }
          }
        ]
      ]
      if (desktop.connectionType === 'rdp') {
        next.dispatchN2.push([
          [
            evt.API_REQUEST,
            {
              method: 'post',
              url: '/userSession/mark',
              data: {
                type: 'guestAssignmentEnd',
                timestamp: new Date(),
                username: R.path(['user', 'user', 'username'])(db),
                realm: R.path(['user', 'realm'])(db),
                guestName: desktop.guestName,
                hostid: desktop.hostid
              }
            }
          ]
        ])
      }
    }
  }
  next.db = R.pipe(
    assocPath(['data', collection, id], guest),
    assocPath(['data', 'desktops', poolId], desktop),
    assocPath(['popup'], popup)
  )
  return next
})

regEventFx(evt.GUEST_REMOVE, ({ db }, _, { collection, id, oldValue }) => {
  const poolId = oldValue.poolId,
    guestName = id
  return {
    db: R.pipe(
      R.dissocPath(['data', collection, guestName]),
      updateIn(
        ['data', 'desktops', poolId],
        R.omit([
          'guestName',
          'ip',
          'userVolume',
          'guestState',
          'sessionStatus',
          'pendingRelease',
          'pendingAssignment',
          'pendingConnect'
        ])
      )
    )
  }
})

regEventFx(evt.GUEST_CHANGE_BATCH, ({ db }, _, changes) => {
  return {
    db: R.reduce(
      (acc, { collection, id, value }) =>
        assocPath(['data', collection, id], value)(acc),
      db,
      changes
    )
  }
})

regEventFx(evt.GUEST_REMOVE_BATCH, ({ db }, _, changes) => {
  const removedGuests = R.map(
    R.prop('id'),
    R.filter(R.propEq('collection', 'guests'), changes)
  )

  return R.mergeAll([
    {
      db: R.reduce(
        (acc, { collection, id }) =>
          R.dissocPath(['data', collection, id], acc),
        db,
        changes
      )
    },
    !isEmpty(removedGuests)
      ? {
          dispatchN2: R.map(id => [evt.CLOSE_CONSOLE, id], removedGuests)
        }
      : {}
  ])
})
