import * as R from 'ramda'
import { evt, success, failure } from '../eventTypes'
import { regEventFx, dispatch } from '../store'
import { first, rename, updateIn } from '../util'
import { DEFAULT_DISPLAY_OPTION, DESKTOP_STATUS } from './static'
import { desktopStatus } from './calcs'
import { setLocalStorageDisplay } from '../localStorage'

regEventFx(evt.GET_ASSIGNMENTS, () => {
  return {
    dispatchN2: [
      [
        evt.API_REQUEST,
        {
          method: 'post'
        }
      ],
      [
        evt.API_REQUEST,
        {
          method: 'get',
          url: `/broker/assignments`,
          success: [success(evt.GET_ASSIGNMENTS)],
          failure: failure(evt.GET_ASSIGNMENTS)
        }
      ]
    ]
  }
})

regEventFx(success(evt.GET_ASSIGNMENTS), ({ db }, _, res) => {
  const autoConnectUserDesktop = R.pathOr(
    false,
    ['configuration', 'autoConnectUserDesktop'],
    db
  )
  const nonVdiDesktops = R.pipe(
    R.filter(pool => pool.type !== 'vdi' && pool.type !== 'standalone'),
    R.map(pool => {
      // TODO is poolid needed at all?
      const {
        id: poolId,
        name: guestPoolName,
        type,
        enableHtml5,
        description,
        enableRdp = true
      } = pool
      const nonVdiGuest = pool.guests.map(guest => {
        const renamedGuest = rename(
          {
            name: 'guestName',
            uuid: 'guestPoolId'
          },
          R.pick(
            [
              'name',
              'ip',
              'hostid',
              'uuid',
              'username',
              'userVolume',
              'guestState',
              'status',
              'sessionStatus',
              'os'
            ],
            guest
          )
        )
        return R.mergeAll([
          renamedGuest,
          { poolId, guestPoolName, type, enableHtml5, enableRdp, description }
        ])
      })
      return nonVdiGuest
    })
  )(R.path(['pools'], res.data))

  const vdiDesktops = R.pipe(
    R.filter(pool => pool.type === 'vdi' || pool.type === 'standalone'),
    R.map(
      ({
        id: guestPoolId,
        name: guestPoolName,
        os,
        guests,
        type,
        enableHtml5,
        description,
        enableRdp = true
      }) => {
        const guest = !first(guests)
          ? {}
          : rename(
              {
                name: 'guestName',
                uuid: 'id'
              },
              R.pick(
                ['name', 'ip', 'userVolume', 'guestState', 'sessionStatus'],
                first(guests)
              )
            )
        return R.mergeAll([
          guest,
          {
            guestPoolId,
            guestPoolName,
            os,
            type,
            enableHtml5,
            enableRdp,
            description
          }
        ])
      }
    )
  )(R.path(['pools'], res.data))
  const allDesktops = R.flatten(R.concat(vdiDesktops, nonVdiDesktops))

  let next = {
    db: R.assocPath(
      ['data', 'desktops'],
      R.indexBy(R.prop('guestPoolId'), allDesktops)
    )
  }
  if (autoConnectUserDesktop && !!vdiDesktops) {
    let desktop = vdiDesktops[0]
    const status = desktopStatus(desktop)
    desktop.pendingConnect = true
    desktop.pendingClientConnect = true
    switch (status) {
      case DESKTOP_STATUS.UNASSIGNED:
        if (next.dispatchN2 === undefined) next.dispatchN2 = []
        next.dispatchN2.push([
          evt.REQUEST_DESKTOP,
          { poolId: desktop.guestPoolId }
        ])
        break
      case DESKTOP_STATUS.READY:
      case DESKTOP_STATUS.CONNECTED:
        setTimeout(
          () =>
            dispatch(evt.CONNECT_DESKTOP, {
              poolId: desktop.guestPoolId,
              guestName: desktop.guestName,
              hostid: desktop.hostid
            }),
          2000
        )
        break
      default:
        setTimeout(
          () =>
            dispatch(evt.SHOW_ALERT, {
              type: 'error',
              message: `Cannot auto connect to guest as it is currently om a ${status} state.`
            }),
          2000
        )
        break
    }
  }
  return next
})

regEventFx(failure(evt.GET_ASSIGNMENTS), ({ db }, _, res) => {
  return {
    dispatch2: [
      evt.SHOW_ALERT,
      {
        type: 'error',
        message: 'Failed to get assignments.'
      }
    ]
  }
})

// Request desktop
regEventFx(
  evt.REQUEST_DESKTOP,
  ({ db }, _, { poolId, guestName, hostid, connectionType }) => {
    return {
      db: R.assocPath(['data', 'desktops', poolId, 'pendingAssignment'], true),
      dispatch2: [
        evt.API_REQUEST,
        {
          method: 'post',
          url: `/broker/assign/${poolId}`,
          success: [
            success(evt.REQUEST_DESKTOP),
            { poolId, guestName, hostid, connectionType }
          ],
          failure: [failure(evt.REQUEST_DESKTOP), poolId]
        }
      ]
    }
  }
)

regEventFx(
  success(evt.REQUEST_DESKTOP),
  ({ db }, _, [res, { poolId, guestName, hostid, connectionType }]) => {
    // console.log({ res })
    // assigned desktop will be received via socket
    let desktop = R.path(['data', 'desktops', poolId], db)
    let popup = R.path(['popup'], db)
    if (R.isNil(desktop)) {
      desktop = rename({ name: 'guestName' }, res.data)
    }
    let next = {}
    desktop.connectionType = connectionType
    if (res.status === 202) {
      delete desktop.pendingAssignment
      next.dispatchN2 = [
        [
          evt.SHOW_ALERT,
          {
            type: 'info',
            message:
              'Received guest, please wait as the system prepares it for use.'
          }
        ]
      ]
    } else {
      delete desktop.pendingAssignment
      next.dispatchN2 = [
        [
          evt.SHOW_ALERT,
          {
            type: 'info',
            message: 'Desktop assigned. Initiating connection'
          }
        ]
      ]
      if (desktop.pendingConnect) {
        desktop.pendingAssignment = false
        const status = desktopStatus(desktop)
        // console.log({ desktop, status })
        if ([DESKTOP_STATUS.READY, DESKTOP_STATUS.CONNECTED].includes(status)) {
          delete desktop.pendingConnect
          if (next.dispatchN2 === undefined) next.dispatchN2 = []

          if (connectionType === 'rdp') {
            next.dispatchN2.push([
              [
                evt.CONNECT_DESKTOP,
                { poolId, guestName: desktop.guestName, hostid }
              ]
            ])
          }
          R.assocPath(['data', 'desktops', poolId], desktop)(db)
        }
      }
      R.assocPath(['data', 'desktops', poolId], desktop)(db)
    }
    next.db = R.pipe(
      R.assocPath(['data', 'desktops', poolId], desktop),
      R.assocPath(['popup'], popup)
    )
    return next
  }
)

regEventFx(failure(evt.REQUEST_DESKTOP), ({ db }, _, [res, poolId]) => {
  return {
    db: R.pipe(
      R.dissocPath(['data', 'desktops', poolId, 'pendingConnect']),
      R.dissocPath(['data', 'desktops', poolId, 'pendingAssignment'])
    ),
    dispatch2: [
      evt.SHOW_ALERT,
      {
        type: 'error',
        message: 'Failed to get request desktop.'
      }
    ]
  }
})

// Release desktop
regEventFx(evt.RELEASE_DESKTOP, ({ db }, _, { poolId, guestName }) => {
  const username = R.path(['user', 'user', 'username'], db)
  return {
    dispatch2: [
      evt.API_REQUEST,
      {
        method: 'post',
        url: `/broker/release`,
        data: { poolId: poolId, username, guest: guestName },
        success: [success(evt.RELEASE_DESKTOP), { poolId, guestName }],
        failure: failure(evt.RELEASE_DESKTOP)
      }
    ]
  }
})

regEventFx(success(evt.RELEASE_DESKTOP), ({ db }, _, [res, { poolId }]) => {
  let next = {
    dispatch2: [
      evt.SHOW_ALERT,
      {
        type: 'info',
        message: 'Successfully requested guest release.'
      }
    ]
  }
  if (res.status === 202) {
    next.db = updateIn(
      ['data', 'desktops', poolId],
      R.assoc('pendingRelease', true)
    )
  }
  return next
})

regEventFx(failure(evt.RELEASE_DESKTOP), ({ db }, _, res) => {
  return {
    dispatch2: [
      evt.SHOW_ALERT,
      {
        type: 'error',
        message: 'Failed to release desktop.'
      }
    ]
  }
})

regEventFx(evt.POWERON_DESKTOP, ({ db }, _, { poolId, guestName }) => {
  const username = R.path(['user', 'user', 'username'], db)
  // console.log({ poolId, guestName, username })
  return {
    dispatch2: [
      evt.API_REQUEST,
      {
        method: 'post',
        url: `/broker/${guestName}/start`,
        data: { poolId: poolId, username },
        success: [success(evt.POWERON_DESKTOP), { poolId, guestName }],
        failure: failure(evt.POWERON_DESKTOP)
      }
    ]
  }
})

regEventFx(success(evt.POWERON_DESKTOP), ({ db }, _, [res, { poolId }]) => {
  let next = {
    dispatch2: [
      evt.SHOW_ALERT,
      {
        type: 'info',
        message: 'Successfully requested to power on guest.'
      }
    ]
  }
  if (res.status === 202) {
    next.db = updateIn(
      ['data', 'desktops', poolId],
      R.assoc('pendingStart', true)
    )
  }
  return next
})

regEventFx(failure(evt.POWERON_DESKTOP), ({ db }, _, res) => {
  return {
    dispatch2: [
      evt.SHOW_ALERT,
      {
        type: 'error',
        message: 'Failed to request power on guest.'
      }
    ]
  }
})

const apiDisplayPayload = v => {
  if (v === 'multimon' || v === 'fullscreen') {
    return { method: v }
  }
  const [width, height] = v.split('x')
  return { method: 'exact', width: Number(width), height: Number(height) }
}

const onRemotePath = () => {
  let path = window.location.pathname.split('/')
  return R.any(x => x === 'remote', path)
}

// Connect desktop
regEventFx(evt.CONNECT_DESKTOP, ({ db }, _, { poolId, guestName, hostid }) => {
  const displaySettings = R.pathOr(
    DEFAULT_DISPLAY_OPTION,
    ['settings', 'display'],
    db
  )

  return {
    db: R.pipe(
      R.dissocPath(['pendingConnect', poolId]),
      R.assocPath(['data', 'desktops', poolId, 'pendingConnect'], true)
    ),
    dispatch2: [
      evt.API_REQUEST,
      {
        method: 'post',
        url: `/broker/${guestName}/connect`,
        data: R.merge(onRemotePath() ? { remote: true } : {}, {
          display: apiDisplayPayload(displaySettings)
        }),
        success: [success(evt.CONNECT_DESKTOP), { guestName, poolId, hostid }],
        failure: [failure(evt.CONNECT_DESKTOP), { guestName, poolId, hostid }],
        headers:
          window.location.href.indexOf('/remote/') !== -1
            ? { 'x-hive-remote': 'REMOTE' }
            : {}
      }
    ]
  }
})

const downloadAsFile = ({ filename, data, type = 'text/plain' }) => {
  let a = document.createElement('a')
  a.href = URL.createObjectURL(new Blob([data], { type }))
  a.download = filename
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

regEventFx(
  success(evt.CONNECT_DESKTOP),
  ({ db }, _, [res, { guestName, poolId, hostid }]) => {
    const autoConnectUserDesktop = R.pathOr(
      false,
      ['configuration', 'autoConnectUserDesktop'],
      db
    )
    downloadAsFile({
      filename: `${guestName}.rdp`,
      data: res.data,
      type: 'application/x-rdp'
    })
    if (autoConnectUserDesktop)
      setTimeout(() => dispatch(evt.LOGOUT_REQUEST), 5000)
    return {
      db: R.dissocPath(['data', 'desktops', poolId, 'pendingConnect'])
    }
  }
)

regEventFx(
  failure(evt.CONNECT_DESKTOP),
  ({ db }, _, [res, { guestName, poolId }]) => {
    return {
      db: R.dissocPath(['data', 'desktops', poolId, 'pendingConnect']),
      dispatch2: [
        evt.SHOW_ALERT,
        {
          type: 'error',
          message: R.pathOr('Failed to connect desktop.', [
            'response',
            'data',
            'message'
          ])(res)
        }
      ]
    }
  }
)

regEventFx(evt.START_EDIT_SETTINGS, () => {
  return {
    db: R.assocPath(['settings', 'isEditing'], true)
  }
})

// TODO. We don't have a place to save the display setting on the backend
// so we're keeping the edited value in the app state as l1 data
regEventFx(evt.SAVE_EDIT_SETTINGS, ({ db }) => {
  setLocalStorageDisplay(R.pathOr('', ['settings', 'display'])(db))
  return {
    db: R.dissocPath(['settings', 'isEditing'])
  }
})

regEventFx(evt.SETTINGS_CLOSE, () => {
  return {
    db: R.dissocPath(['settings', 'isEditing'])
  }
})
