import { createRouter } from './doubleRouter'
import { createBrowserHistory as createHistory } from 'history'
import * as R from 'ramda'
import { evt } from './eventTypes'
import { BASENAME_REGEX } from './config'
import { getLocalStorageToken } from './localStorage'
import { routes, routeIds, unprotectedRoutes } from './routes'
import { dispatch, regEventFx, regFx, setState } from './store'
import { getRouteId } from './subs'
import { first, updateIn } from './util'

/* NOTE: Not strictly necessary to use history.js library */
const history = createHistory()
const { pushNamedRoute, replaceNamedRoute, listen } = createRouter({
  history,
  routes,
  basenamePattern: BASENAME_REGEX
})

regFx('route', args => pushNamedRoute.apply(null, args))
regFx('redirect', args => replaceNamedRoute.apply(null, args))

const isProtectedRoute = routeId =>
  R.not(R.contains(routeId, unprotectedRoutes))

const getRouteEffects = ({ db, match, type, route, prevRoute }) => {
  const initialLoad = type === 'INITIAL'
  if (
    !getLocalStorageToken() &&
    (!match.route || isProtectedRoute(match.route.id))
  ) {
    return { redirect: [routeIds.LOGIN] }
  }

  if (match.id === 'not-found') {
    return { redirect: [routeIds.GUESTS] }
  }

  if (initialLoad && isProtectedRoute(match.route.id)) {
    return { dispatchN2: [[evt.GET_USER], [evt.GET_ASSIGNMENTS]] }
  }

  const helpers = {
    prevRoute,
    getRouteId,
    setState
  }
  // Not the same as a route change or transition.
  // An exit is when we've transitioned from one named route to a different named route
  const onExit =
    prevRoute &&
    prevRoute.id !== match.route.id &&
    R.path(['onExit'], prevRoute) &&
    R.path(['onExit'], prevRoute)(db, match.params, helpers)

  // Initial load onEnters are handled in the fx handler for INITIAL_DATA
  // to avoid race conditions
  const onEnter =
    !initialLoad &&
    R.path(['onEnter'], route) &&
    R.path(['onEnter'], route)(db, match.params, helpers)

  // ...except when we get no initial data event and need to get data (e.g. login)
  const onEnterAlways =
    // (initialLoad || !initialLoad) &&
    R.path(['onEnterAlways'], route) &&
    R.path(['onEnterAlways'], route)(db, match.params, helpers)

  if (onEnter || onExit || onEnterAlways) {
    const events = R.reduce(
      (a, x) => {
        if (Array.isArray(first(x))) {
          return R.reduce((a2, x2) => R.append(x2, a2), a, x)
        }
        return R.append(x, a)
      },
      [],
      R.filter(R.identity, [onEnterAlways, onEnter, onExit])
    )
    return { dispatchN2: events }
  }

  return {}
}

regEventFx(evt.ROUTE_CHANGED, ({ db }, _, locationAndMatch) => {
  const { match, type } = locationAndMatch
  const route = R.path(['match', 'route'], locationAndMatch)
  const prevRoute = R.path(
    ['match', 'route'],
    R.last(R.pathOr([], ['router', 'history'], db))
  )
  const effects = getRouteEffects({ db, match, type, route, prevRoute })

  return {
    db: {
      ...db,
      router: updateIn(
        ['history'],
        (history = []) => R.take(5, R.concat([locationAndMatch], history)),
        R.merge(R.prop('router', db), locationAndMatch)
      )
    },
    ...effects
  }
})

export const startRouter = () =>
  listen(locationAndMatch => {
    dispatch(evt.ROUTE_CHANGED, locationAndMatch)
  })
