export const hexagons = color =>
  `<svg width="300px" height="436px" viewBox="0 0 300 436" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <rect id="path-1" x="0" y="0" width="300" height="436"></rect>
    <linearGradient x1="50%" y1="0%" x2="50%" y2="80.3370799%" id="linearGradient-3">
      <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
      <stop stop-color="#FFFFFF" offset="100%"></stop>
    </linearGradient>
  </defs>
  <g id="Experiments" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Sankey-(2)-Copy-3" transform="translate(-362.000000, -413.000000)">
      <g id="Group-2" transform="translate(362.000000, 413.000000)">
        <g id="Group-3">
          <g id="background">
            <mask id="mask-2" fill="white">
              <use xlink:href="#path-1"></use>
            </mask>
            <use id="Mask" fill="#FFFFFF" xlink:href="#path-1"></use>
            <g id="hive-bg" mask="url(#mask-2)" stroke="${color}">
              <g transform="translate(119.000000, 142.500000) scale(-1, 1) translate(-119.000000, -142.500000) translate(-16.000000, -56.000000)">
                <polygon id="Path_2" points="174.608802 54.8430073 174.608802 36.5569438 158.765281 27.4469193 142.92176 36.5569438 142.92176 54.8430073 158.765281 64.0190465"></polygon>
                <polygon id="Path_4" points="190.452323 82.5691687 190.452323 64.2831051 174.608802 55.1730807 158.765281 64.2831051 158.765281 82.5691687 174.608802 91.7452078"></polygon>
                <polygon id="Path_6" points="206.295844 110.29533 206.295844 92.0092665 190.452323 82.8992421 174.608802 92.0092665 174.608802 110.29533 190.452323 119.471369"></polygon>
                <polygon id="Path_14" points="190.452323 138.021491 190.452323 119.735428 174.608802 110.625403 158.765281 119.735428 158.765281 138.021491 174.608802 147.197531"></polygon>
                <polygon id="Path_23" points="174.608802 165.747653 174.608802 147.461589 158.765281 138.351565 142.92176 147.461589 142.92176 165.747653 158.765281 174.923692"></polygon>
                <polygon id="Path_36" points="174.608802 221.199976 174.608802 202.913912 158.765281 193.803888 142.92176 202.913912 142.92176 221.199976 158.765281 230.376015"></polygon>
                <polygon id="Path_45" points="190.452323 248.926137 190.452323 230.640073 174.608802 221.530049 158.765281 230.640073 158.765281 248.926137 174.608802 258.102176"></polygon>
                <polygon id="Path_59" points="190.452323 304.37846 190.452323 286.092396 174.608802 276.982372 158.765281 286.092396 158.765281 304.37846 174.608802 313.554499"></polygon>
                <polygon id="Path_66" points="174.608802 332.104621 174.608802 313.818557 158.765281 304.708533 142.92176 313.818557 142.92176 332.104621 158.765281 341.28066"></polygon>
                <polygon id="Path_72" points="190.452323 359.830782 190.452323 341.544719 174.608802 332.434694 158.765281 341.544719 158.765281 359.830782 174.608802 369.006822"></polygon>
                <polygon id="Path_52" points="206.295844 276.652298 206.295844 258.366235 190.452323 249.25621 174.608802 258.366235 174.608802 276.652298 190.452323 285.828337"></polygon>
                <polygon id="Path_30" points="190.452323 193.473814 190.452323 175.187751 174.608802 166.077726 158.765281 175.187751 158.765281 193.473814 174.608802 202.649853"></polygon>
                <polygon id="Path_9" points="237.982885 110.29533 237.982885 92.0092665 222.139364 82.8992421 206.295844 92.0092665 206.295844 110.29533 222.139364 119.471369"></polygon>
                <polygon id="Path_13" points="222.139364 138.021491 222.139364 119.735428 206.295844 110.625403 190.452323 119.735428 190.452323 138.021491 206.295844 147.197531"></polygon>
                <polygon id="Path_22" points="206.295844 165.747653 206.295844 147.461589 190.452323 138.351565 174.608802 147.461589 174.608802 165.747653 190.452323 174.923692"></polygon>
                <polygon id="Path_35" points="206.295844 221.199976 206.295844 202.913912 190.452323 193.803888 174.608802 202.913912 174.608802 221.199976 190.452323 230.376015"></polygon>
                <polygon id="Path_43" points="222.139364 248.926137 222.139364 230.640073 206.295844 221.530049 190.452323 230.640073 190.452323 248.926137 206.295844 258.102176"></polygon>
                <polygon id="Path_58" points="222.139364 304.37846 222.139364 286.092396 206.295844 276.982372 190.452323 286.092396 190.452323 304.37846 206.295844 313.554499"></polygon>
                <polygon id="Path_65" points="206.295844 332.104621 206.295844 313.818557 190.452323 304.708533 174.608802 313.818557 174.608802 332.104621 190.452323 341.28066"></polygon>
                <polygon id="Path_51" points="237.982885 276.652298 237.982885 258.366235 222.139364 249.25621 206.295844 258.366235 206.295844 276.652298 222.139364 285.828337"></polygon>
                <polygon id="Path_31" points="222.139364 193.473814 222.139364 175.187751 206.295844 166.077726 190.452323 175.187751 190.452323 193.473814 206.295844 202.649853"></polygon>
                <polygon id="Path_10" points="269.669927 110.29533 269.669927 92.0092665 253.826406 82.8992421 237.982885 92.0092665 237.982885 110.29533 253.826406 119.471369"></polygon>
                <polygon id="Path_12" points="253.826406 138.021491 253.826406 119.735428 237.982885 110.625403 222.139364 119.735428 222.139364 138.021491 237.982885 147.197531"></polygon>
                <polygon id="Path_24" points="237.982885 165.747653 237.982885 147.461589 222.139364 138.351565 206.295844 147.461589 206.295844 165.747653 222.139364 174.923692"></polygon>
                <polygon id="Path_34" points="237.982885 221.199976 237.982885 202.913912 222.139364 193.803888 206.295844 202.913912 206.295844 221.199976 222.139364 230.376015"></polygon>
                <polygon id="Path_44" points="253.826406 248.926137 253.826406 230.640073 237.982885 221.530049 222.139364 230.640073 222.139364 248.926137 237.982885 258.102176"></polygon>
                <polygon id="Path_57" points="253.826406 304.37846 253.826406 286.092396 237.982885 276.982372 222.139364 286.092396 222.139364 304.37846 237.982885 313.554499"></polygon>
                <polygon id="Path_64" points="237.982885 332.104621 237.982885 313.818557 222.139364 304.708533 206.295844 313.818557 206.295844 332.104621 222.139364 341.28066"></polygon>
                <polygon id="Path_41" points="269.669927 221.199976 269.669927 202.913912 253.826406 193.803888 237.982885 202.913912 237.982885 221.199976 253.826406 230.376015"></polygon>
                <polygon id="Path_32" points="253.826406 193.473814 253.826406 175.187751 237.982885 166.077726 222.139364 175.187751 222.139364 193.473814 237.982885 202.649853"></polygon>
                <polygon id="Path_11" points="253.826406 82.5691687 253.826406 64.2831051 237.982885 55.1730807 222.139364 64.2831051 222.139364 82.5691687 237.982885 91.7452078"></polygon>
                <polygon id="Path_11-Copy" points="222.126406 82.5691687 222.126406 64.2831051 206.282885 55.1730807 190.439364 64.2831051 190.439364 82.5691687 206.282885 91.7452078"></polygon>
                <polygon id="Path_3" points="142.92176 54.8430073 142.92176 36.5569438 127.07824 27.4469193 111.234719 36.5569438 111.234719 54.8430073 127.07824 64.0190465"></polygon>
                <polygon id="Path_5" points="158.765281 82.5691687 158.765281 64.2831051 142.92176 55.1730807 127.07824 64.2831051 127.07824 82.5691687 142.92176 91.7452078"></polygon>
                <polygon id="Path_7" points="174.608802 110.29533 174.608802 92.0092665 158.765281 82.8992421 142.92176 92.0092665 142.92176 110.29533 158.765281 119.471369"></polygon>
                <polygon id="Path_15" points="158.765281 138.021491 158.765281 119.735428 142.92176 110.625403 127.07824 119.735428 127.07824 138.021491 142.92176 147.197531"></polygon>
                <polygon id="Path_21" points="142.92176 165.747653 142.92176 147.461589 127.07824 138.351565 111.234719 147.461589 111.234719 165.747653 127.07824 174.923692"></polygon>
                <polygon id="Path_37" points="142.92176 221.199976 142.92176 202.913912 127.07824 193.803888 111.234719 202.913912 111.234719 221.199976 127.07824 230.376015"></polygon>
                <polygon id="Path_46" points="158.765281 248.926137 158.765281 230.640073 142.92176 221.530049 127.07824 230.640073 127.07824 248.926137 142.92176 258.102176"></polygon>
                <polygon id="Path_60" points="158.765281 304.37846 158.765281 286.092396 142.92176 276.982372 127.07824 286.092396 127.07824 304.37846 142.92176 313.554499"></polygon>
                <polygon id="Path_67" points="142.92176 332.104621 142.92176 313.818557 127.07824 304.708533 111.234719 313.818557 111.234719 332.104621 127.07824 341.28066"></polygon>
                <polygon id="Path_73" points="158.765281 359.830782 158.765281 341.544719 142.92176 332.434694 127.07824 341.544719 127.07824 359.830782 142.92176 369.006822"></polygon>
                <polygon id="Path_53" points="174.608802 276.652298 174.608802 258.366235 158.765281 249.25621 142.92176 258.366235 142.92176 276.652298 158.765281 285.828337"></polygon>
                <polygon id="Path_29" points="158.765281 193.473814 158.765281 175.187751 142.92176 166.077726 127.07824 175.187751 127.07824 193.473814 142.92176 202.649853"></polygon>
                <polygon id="Path_8" points="142.92176 110.29533 142.92176 92.0092665 127.07824 82.8992421 111.234719 92.0092665 111.234719 110.29533 127.07824 119.471369"></polygon>
                <polygon id="Path_16" points="127.07824 138.021491 127.07824 119.735428 111.234719 110.625403 95.391198 119.735428 95.391198 138.021491 111.234719 147.197531"></polygon>
                <polygon id="Path_20" points="111.234719 165.747653 111.234719 147.461589 95.391198 138.351565 79.5476773 147.461589 79.5476773 165.747653 95.391198 174.923692"></polygon>
                <polygon id="Path_38" points="111.234719 221.199976 111.234719 202.913912 95.391198 193.803888 79.5476773 202.913912 79.5476773 221.199976 95.391198 230.376015"></polygon>
                <polygon id="Path_47" points="127.07824 248.926137 127.07824 230.640073 111.234719 221.530049 95.391198 230.640073 95.391198 248.926137 111.234719 258.102176"></polygon>
                <polygon id="Path_61" points="127.07824 304.37846 127.07824 286.092396 111.234719 276.982372 95.391198 286.092396 95.391198 304.37846 111.234719 313.554499"></polygon>
                <polygon id="Path_68" points="111.234719 332.104621 111.234719 313.818557 95.391198 304.708533 79.5476773 313.818557 79.5476773 332.104621 95.391198 341.28066"></polygon>
                <polygon id="Path_74" points="127.07824 359.830782 127.07824 341.544719 111.234719 332.434694 95.391198 341.544719 95.391198 359.830782 111.234719 369.006822"></polygon>
                <polygon id="Path_75" points="142.92176 387.556944 142.92176 369.27088 127.07824 360.160856 111.234719 369.27088 111.234719 387.556944 127.07824 396.732983"></polygon>
                <polygon id="Path_54" points="142.92176 276.652298 142.92176 258.366235 127.07824 249.25621 111.234719 258.366235 111.234719 276.652298 127.07824 285.828337"></polygon>
                <polygon id="Path_28" points="127.07824 193.473814 127.07824 175.187751 111.234719 166.077726 95.391198 175.187751 95.391198 193.473814 111.234719 202.649853"></polygon>
                <polygon id="Path_17" points="95.391198 138.021491 95.391198 119.735428 79.5476773 110.625403 63.7041565 119.735428 63.7041565 138.021491 79.5476773 147.197531"></polygon>
                <polygon id="Path_19" points="79.5476773 165.747653 79.5476773 147.461589 63.7041565 138.351565 47.8606357 147.461589 47.8606357 165.747653 63.7041565 174.923692"></polygon>
                <polygon id="Path_39" points="79.5476773 221.199976 79.5476773 202.913912 63.7041565 193.803888 47.8606357 202.913912 47.8606357 221.199976 63.7041565 230.376015"></polygon>
                <polygon id="Path_48" points="95.391198 248.926137 95.391198 230.640073 79.5476773 221.530049 63.7041565 230.640073 63.7041565 248.926137 79.5476773 258.102176"></polygon>
                <polygon id="Path_62" points="95.391198 304.37846 95.391198 286.092396 79.5476773 276.982372 63.7041565 286.092396 63.7041565 304.37846 79.5476773 313.554499"></polygon>
                <polygon id="Path_69" points="79.5476773 332.104621 79.5476773 313.818557 63.7041565 304.708533 47.8606357 313.818557 47.8606357 332.104621 63.7041565 341.28066"></polygon>
                <polygon id="Path_55" points="111.234719 276.652298 111.234719 258.366235 95.391198 249.25621 79.5476773 258.366235 79.5476773 276.652298 95.391198 285.828337"></polygon>
                <polygon id="Path_27" points="95.391198 193.473814 95.391198 175.187751 79.5476773 166.077726 63.7041565 175.187751 63.7041565 193.473814 79.5476773 202.649853"></polygon>
                <polygon id="Path_25" points="47.8606357 165.747653 47.8606357 147.461589 32.0171149 138.351565 16.1735941 147.461589 16.1735941 165.747653 32.0171149 174.923692"></polygon>
                <polygon id="Path_40" points="47.8606357 221.199976 47.8606357 202.913912 32.0171149 193.803888 16.1735941 202.913912 16.1735941 221.199976 32.0171149 230.376015"></polygon>
                <polygon id="Path_49" points="63.7041565 248.926137 63.7041565 230.640073 47.8606357 221.530049 32.0171149 230.640073 32.0171149 248.926137 47.8606357 258.102176"></polygon>
                <polygon id="Path_63" points="63.7041565 304.37846 63.7041565 286.092396 47.8606357 276.982372 32.0171149 286.092396 32.0171149 304.37846 47.8606357 313.554499"></polygon>
                <polygon id="Path_70" points="47.8606357 332.104621 47.8606357 313.818557 32.0171149 304.708533 16.1735941 313.818557 16.1735941 332.104621 32.0171149 341.28066"></polygon>
                <polygon id="Path_71" points="63.7041565 359.830782 63.7041565 341.544719 47.8606357 332.434694 32.0171149 341.544719 32.0171149 359.830782 47.8606357 369.006822"></polygon>
                <polygon id="Path_56" points="79.5476773 276.652298 79.5476773 258.366235 63.7041565 249.25621 47.8606357 258.366235 47.8606357 276.652298 63.7041565 285.828337"></polygon>
                <polygon id="Path_26" points="63.7041565 193.473814 63.7041565 175.187751 47.8606357 166.077726 32.0171149 175.187751 32.0171149 193.473814 47.8606357 202.649853"></polygon>
                <polygon id="Path_33" points="32.0171149 193.473814 32.0171149 175.187751 16.1735941 166.077726 0.33007335 175.187751 0.33007335 193.473814 16.1735941 202.649853"></polygon>
                <polygon id="Path_18" points="79.5476773 110.29533 79.5476773 92.0092665 63.7041565 82.8992421 47.8606357 92.0092665 47.8606357 110.29533 63.7041565 119.471369"></polygon>
              </g>
            </g>
            <rect id="Rectangle-2" fill="url(#linearGradient-3)" mask="url(#mask-2)" x="-1" y="0" width="300" height="436"></rect>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>`
