import { component } from 'framework-x'
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Div } from '../utils/styled-utils'
import { brokerTheme } from '../configuration/subs'
import { evt } from '../eventTypes'
import { UserIcon } from '../icons'
import { DropdownContainer, DropdownItem } from './shared'
import theme from './theme'
import { createSub } from '../utils'
import { dispatch } from '../store'

export const Header = component(
  'Header',
  createSub({ brokerTheme }),
  ({ brokerTheme }) => (
    <Div
      className="header-top"
      css={{
        height: 60,
        minHeight: 60,
        backgroundColor: '#fff',
        display: 'grid',
        gridTemplateColumns: `1fr calc(1fr - 40px) 1fr`,
        marginRight: '20px',
        marginLeft: '20px',
        // gridTemplateRows: '1fr',
        gridTemplateAreas: `"logo . tools"`,
        alignItems: 'center',
        justifyItems: 'center',
        borderBottom: theme.border,
        '@media screen and (max-width: 400px)': {
          marginRight: '0px',
          marginLeft: '0px',
          gridTemplateColumns: `1fr 1fr 1fr`
        }
      }}
    >
      <img
        style={{
          gridArea: 'logo',
          paddingTop: 4,
          dispatch: 'flex',
          justifyContent: 'flex-start',
          justifySelf: 'start',
          position: 'relative',
          left: 20,
          maxHeight: 46
        }}
        src={
          !!brokerTheme.logo
            ? 'data:image/png;base64,' + brokerTheme.logo
            : null
        }
        alt=""
      />

      <Div
        className="header-toolbar"
        css={{
          gridArea: 'tools',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          justifySelf: 'end',
          position: 'relative',
          right: 20
        }}
      >
        <DropdownContainer
          button={
            <Div css={{ cursor: 'pointer' }}>
              <UserIcon />
            </Div>
          }
          closeOnClick={true}
          dropdownCss={{ width: 220 }}
        >
          <Div
            css={{
              position: 'relative',
              left: -220 + 30,
              textAlign: 'right',
              background: theme.white,
              color: theme.dark,
              boxShadow: theme.dropdownShadow
            }}
          >
            <DropdownItem
              css={{ paddingRight: 20 }}
              onClick={() => dispatch(evt.LOGOUT_REQUEST)}
            >
              Log out
            </DropdownItem>
          </Div>
        </DropdownContainer>
      </Div>
    </Div>
  )
)
