export const success = key => `${key}-success`
export const failure = key => `${key}-failure`

export const evt = {
  UNHANDLED_ASYNC_ERROR: 'async-error',
  INITIALIZE_DB: 'initialize-db',
  ROUTE_CHANGED: 'route-changed',
  CONNECT_SOCKET: 'api-socket-connect',
  API_SOCKET_CONNECTED: 'api-socket-connected',
  SOCKET_CONNECTED: 'socket-connected',
  SOCKET_DISCONNECTED: 'socket-disconnected',
  GET_USER: 'get-user',

  NAV_TO: 'nav-to',
  INITIAL_DATA: 'initial-data',
  COLLECTION_INITIAL_VALUE: 'collection-initial-value',

  GUEST_CHANGE: 'guest-change',
  GUEST_REMOVE: 'guest-remove',
  GUEST_CHANGE_BATCH: 'guest-change-batch',
  GUEST_REMOVE_BATCH: 'guest-remove-batch',

  API_REQUEST: 'api-request',
  GET_CLUSTERS: 'get-cluster',
  GET_LICENSE: 'get-license',
  QUERY_SUBSCRIPTION_REGISTERED: 'table-sub-registered',

  CHANGE_VALUE: 'change-value',
  TOGGLE_LIST_VALUE: 'toggle-list-value',
  LOGIN_REQUEST: 'login-request',
  MFA_TOKEN_REQUEST: 'mfa-token-request',
  MFA_AUTH_REQUEST: 'mfa-auth-request',
  LOGOUT_REQUEST: 'logout-request',
  LOGIN_GET_INITIAL_REQUIRED_DATA: 'login/get-initial-required-data',
  GET_REALMS: 'realms/get-all',
  GET_ASSIGNMENTS: 'assignments/get-all',
  ACKNOWLEDGE_DISCLAIMER: 'disclaimer/acknowledge',
  RESET_ACKNOWLEDGE_DISCLAIMER: 'disclaimer/reset-acknowledge',
  REQUEST_DESKTOP: 'desktop/request',
  REQUEST_CLIENT: 'desktop/client/request',
  CONNECT_DESKTOP: 'desktop/connect',
  CONNECT_CLIENT: 'desktop/client/connect',
  RELEASE_DESKTOP: 'desktop/release',
  POWERON_DESKTOP: 'desktop/poweron',

  START_EDIT_SETTINGS: 'settings/start-edit',
  CANCEL_EDIT_SETTINGS: 'settings/cancel-edit',
  SAVE_EDIT_SETTINGS: 'settings/save-edit',
  SETTINGS_CLOSE: 'settings/close',
  SHOW_ALERT: 'alert/show',
  HIDE_ALERT: 'alert/hide'
}
