import 'typeface-montserrat'
import { Provider } from 'framework-x'
import React from 'react'
import ReactDOM from 'react-dom'
import { axiosConfig } from './events/generalEvents'
import { evt } from './eventTypes'
import 'react-vis/dist/style.css'
import './index.css'
import { startRouter } from './routing'
import { dispatch, getState, setState, subscribeToState } from './store'
import './events'
import App from './views/App'
import './debug.js'
import './axiosFx'
import axios from 'axios'
import * as R from 'ramda'
import { defaultConfiguration } from './configuration/subs'
import { getLocalStorageDisplay } from './localStorage'

const render = () =>
  ReactDOM.render(
    <Provider
      getState={getState}
      dispatch={dispatch}
      subscribeToState={subscribeToState}
    >
      <App />
    </Provider>,
    document.getElementById('root')
  )

let currConfig = defaultConfiguration

const getConfig = first =>
  axios(
    axiosConfig(R.__, {
      method: 'get',
      url: '/broker/configuration'
    })
  )
    .then(res => {
      currConfig = res.data
      const display = getLocalStorageDisplay()
      if (first) {
        dispatch(evt.INITIALIZE_DB, display)
      }
      setState(R.assoc('configuration', res.data, getState()))
      if (first) {
        startRouter()
        render()
      }
    })
    .catch(err => {
      currConfig.unavailable = true
      if (first) {
        dispatch(evt.INITIALIZE_DB)
      }
      setState(R.assoc('configuration', currConfig, getState()))
      if (first) {
        startRouter()
        render()
      }
    })
    .finally(() => {
      currConfig.enabled !== true && setTimeout(getConfig, 5000)
    })

getConfig(true)
