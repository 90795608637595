export const DESKTOP_STATUS = {
  UNASSIGNED: 'unassigned',
  ASSIGNED: 'assigned',
  CONNECTED: 'connected',
  READY: 'ready',
  PREPARE: 'preparing',
  STOPPED: 'shutdown',
  STARTING: 'starting',
  UNAVAILABLE: 'unavailable',
  FAILED: 'failed',
  RELEASING: 'releasing'
}

// TODO. Find out how the client receives this value and store in settings/display
export const DEFAULT_DISPLAY_OPTION = 'multimon'
