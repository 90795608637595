import { Svg } from '../utils/styled-utils'
import React from 'react'

export const PowerOnIcon = ({ ...css }) => (
  <Svg {...css} width="16px" height="16px" viewBox="0 0 16 16">
    <g fill="#FAFAFA">
      <g>
        <g transform="scale(0.03125)">
          <path
            d="M366.463,172.539c-8.552-9.595-23.262-10.439-32.858-1.885c-9.595,8.552-10.439,23.263-1.885,32.858
            c16.561,18.578,25.681,42.532,25.682,67.449c0,55.913-45.489,101.402-101.402,101.402s-101.402-45.489-101.402-101.402
            c0-24.919,9.12-48.873,25.682-67.449c8.554-9.595,7.709-24.306-1.885-32.86c-9.595-8.552-24.306-7.708-32.86,1.885
            c-24.171,27.114-37.485,62.068-37.485,98.423C108.052,352.54,174.421,418.909,256,418.909S403.948,352.54,403.948,270.96
            C403.946,234.606,390.634,199.652,366.463,172.539z"
          />
        </g>
      </g>
      <g>
        <g transform="scale(0.03125)">
          <path
            d="M256,93.091c-12.853,0-23.273,10.42-23.273,23.273v99.739c0,12.853,10.42,23.273,23.273,23.273
            c12.853,0,23.273-10.42,23.273-23.273v-99.739C279.273,103.511,268.853,93.091,256,93.091z"
          />
        </g>
      </g>
      <g>
        <g transform="scale(0.03125)">
          <path
            d="M256,0C114.842,0,0,114.842,0,256s114.842,256,256,256s256-114.842,256-256S397.158,0,256,0z M256,465.455
            c-115.495,0-209.455-93.961-209.455-209.455S140.505,46.545,256,46.545S465.455,140.507,465.455,256S371.493,465.455,256,465.455z
            "
          />
        </g>
      </g>
    </g>
  </Svg>
)
