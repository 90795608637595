import { mapKeys } from './ramda-helpers'
import { createSelector, createStructuredSelector } from './reselect-raw'

/*
 * derive (fn, fn)
 * der * dervie ('foo', [fn,fn],fn)
ive ([fn,fn], fn)
 * derive ('foo', [fn,fn],fn)
 * derive ([fn,fn],fn, {name:'foo', useValueHash:true})
 */
const derive = (...args) => {
  if (typeof args[0] === 'string') {
    const sel = createSelector.apply(null, args.slice(1))
    sel.selectorName = args[0]
    return sel
  }
  if (typeof args[args.length - 1] === 'object') {
    const { name, logIt, useValueHash } = args[args.length - 1]
    const sel = createSelector.apply(null, args.slice(0, args.length - 1))
    if (name) sel.selectorName = name
    if (logIt) sel.logIt = logIt
    if (useValueHash) sel.useValueHash = useValueHash
    return sel
  }
  return createSelector.apply(null, args)
}

const caseFirst = str => str[0].toLowerCase() + str.substring(1)

/* Converts anything of the form `getBar` to `bar` */
const createSub = obj =>
  createStructuredSelector(
    mapKeys(
      key => (key.startsWith('get') ? caseFirst(key.substr(3)) : key),
      obj
    )
  )

export { createSub, derive }
