import * as R from 'ramda'
import { evt, success, failure } from '../eventTypes'
import {
  deleteLocalStorageToken,
  setLocalStorageToken,
  getLocalStorageToken
} from '../localStorage'
import { routeIds } from '../routes'
import { regEventFx, dispatch } from '../store'
import { getLoginForm } from '../subs/authCalcs'
import jwtDecode from 'jwt-decode'
import { apiSocket, updateSocketAuth } from '../socketApi'
import { first, rename } from '../util'

const realmRetry = timeout =>
  setTimeout(() => {
    dispatch([evt.GET_REALMS])
  }, timeout || 5000)

regEventFx(evt.LOGIN_GET_INITIAL_REQUIRED_DATA, () => {
  return {
    dispatch2: [evt.GET_REALMS]
  }
})

regEventFx(evt.GET_REALMS, () => {
  return {
    dispatch2: [
      evt.API_REQUEST,
      {
        method: 'get',
        url: `/realms`,
        success: success(evt.GET_REALMS),
        failure: failure(evt.GET_REALMS)
      }
    ]
  }
})

regEventFx(success(evt.GET_REALMS), ({ db }, _, res) => {
  let hideRealms = R.path(['configuration', 'hideRealms'])(db)
  let realms = res.data.filter(realm => realm.name !== 'local')
  const firstRealmAlpha = first(
    R.sortBy(R.compose(R.toLower, R.prop('name')), realms)
  )
  return R.isEmpty(realms)
    ? realmRetry()
    : {
        db: R.pipe(
          R.assocPath(['data', 'realms'], R.indexBy(R.prop('name'), realms)),
          R.assocPath(
            ['forms', 'login', 'realm'],
            hideRealms ? undefined : R.prop('name', firstRealmAlpha)
          )
        )
      }
})

regEventFx(failure(evt.GET_REALMS), ({ db }, _, res) => {
  console.error('get realms error:', res)
  realmRetry()
  return {
    db: R.assocPath(['data', 'realms'], {}),
    dispatch2: [
      evt.SHOW_ALERT,
      {
        type: 'error',
        message: 'Failed to get realm information.'
      }
    ]
  }
})

regEventFx(evt.LOGIN_REQUEST, ({ db }, _, __) => {
  const loginForm = getLoginForm(db)
  let payload = R.omit(['errors', 'timestamp', 'passcode'], loginForm)
  if (payload.username.indexOf('@') !== -1) {
    delete payload.realm
  }

  return {
    db: R.pipe(
      R.assocPath(['forms', 'login', 'requesting'], true),
      R.dissocPath(['forms', 'login', 'errors'])
    ),
    dispatch2: [
      evt.API_REQUEST,
      {
        method: 'post',
        url: '/authBrokerUser',
        data: payload,
        success: success(evt.LOGIN_REQUEST),
        failure: failure(evt.LOGIN_REQUEST)
      }
    ]
  }
})

regEventFx(success(evt.LOGIN_REQUEST), ({ db }, _, res) => {
  const token = R.path(['data', 'token'], res)
  const pools = R.path(['data', 'pools'], res)
  const user = jwtDecode(token)
  setLocalStorageToken(token)
  if (apiSocket.connected) {
    apiSocket.close()
  }
  updateSocketAuth()
  apiSocket.connect()
  return {
    db: R.pipe(
      R.assocPath(['pools'], pools),
      R.dissocPath(['forms', 'login']),
      R.assocPath(['user'], R.omit(['iat', 'exp'], user))
    )(db),
    dispatch2: [evt.NAV_TO, routeIds.GUESTS]
  }
})

regEventFx(failure(evt.LOGIN_REQUEST), ({ db }, _, res) => {
  console.log({ res }, 'login failure')
  switch (true) {
    case R.pathEq(['response', 'status'], 404)(res) &&
      R.pathSatisfies(message => /Realm/.test(message), [
        'response',
        'data',
        'message'
      ])(res):
      return {
        db: R.pipe(
          R.dissocPath(['forms', 'login', 'requesting']),
          R.assocPath(
            ['forms', 'login', 'errors', 'username'],
            'Username not found, please verify'
          )
        )
      }
    case R.pathEq(['response', 'status'], 404)(res):
      return {
        db: R.assocPath(['configuration', 'noPools'], true)
      }
    case R.pathEq(['response', 'status'], 417)(res):
      return {
        db: R.pipe(
          R.dissocPath(['forms', 'login', 'requesting']),
          R.assocPath(
            ['forms', 'login', 'errors', 'token'],
            'Token validation failed'
          )
        )
      }
    case R.pathEq(['response', 'status'], 412)(res) &&
      /Token|MFA/.test(res.response.data.message):
      return {
        db: R.pipe(
          R.dissocPath(['forms', 'login', 'requesting']),
          R.assocPath(['forms', 'login', 'errors', 'token'], 'Token required')
        )
      }
    case R.pathEq(['response', 'status'], 412)(res) &&
      /upn/.test(res.response.data.message):
      return {
        db: R.pipe(
          R.dissocPath(['forms', 'login', 'requesting']),
          R.assocPath(
            ['forms', 'login', 'errors', 'username'],
            'A UPN username is required (user@domain.com)'
          )
        )
      }
    case R.pathEq(['response', 'status'], 400)(res) &&
      /password/.test(JSON.stringify(res.response.data)):
      return {
        db: R.pipe(
          R.dissocPath(['forms', 'login', 'requesting']),
          R.assocPath(
            ['forms', 'login', 'errors', 'password'],
            'Password required'
          )
        )
      }
    case R.pathEq(['response', 'status'], 403)(res) &&
      /MFA/.test(JSON.stringify(res.response.data)):
      return {
        db: R.pipe(
          R.dissocPath(['forms', 'login', 'requesting']),
          R.assocPath(
            ['forms', 'login', 'errors', 'token'],
            'Token validation failed'
          )
        )
      }
    default:
      return {
        db: R.pipe(
          R.dissocPath(['forms', 'login', 'requesting']),
          R.assocPath(
            [
              'forms',
              'login',
              'errors',
              /MFA|Token/.test(JSON.stringify(res.response.data))
                ? 'token'
                : 'password'
            ],
            `Authentication failed (${R.pathOr(res.response.data || 'unknown', [
              'response',
              'data',
              'message'
            ])(res)})`
          )
        )
      }
  }
})

regEventFx(evt.LOGOUT_REQUEST, ({ db }, _, __) => {
  deleteLocalStorageToken()
  if (apiSocket.connected) {
    apiSocket.close()
  }
  return {
    db: R.dissocPath(['user']),
    dispatch2: [evt.NAV_TO, routeIds.LOGIN]
  }
})

regEventFx(evt.GET_USER, ({ db }, _, __) => {
  const token = getLocalStorageToken()

  return {
    db: R.assocPath(['data', 'token'], token),
    dispatch2: [
      evt.API_REQUEST,
      {
        method: 'get',
        url: '/auth/me',
        success: success(evt.GET_USER),
        failure: failure(evt.GET_USER)
      }
    ]
  }
})

regEventFx(success(evt.GET_USER), ({ db }, _, res) => {
  const token = R.path(['data', 'token'], db)
  const user = jwtDecode(token)
  return {
    db: R.assocPath(['user'], R.omit(['iat', 'exp'], user)),
    dispatch2: [evt.CONNECT_SOCKET]
  }
})

regEventFx(evt.CONNECT_SOCKET, () => apiSocket.connect())

regEventFx(failure(evt.GET_USER), (__, _, err) => {
  if (R.path(['response', 'status'], err) === 401)
    return {
      route: [routeIds.LOGIN, {}]
    }
})

regEventFx(evt.ACKNOWLEDGE_DISCLAIMER, () => ({
  db: R.assocPath(['login', 'disclaimerAcknowledged'], true)
}))

regEventFx(evt.RESET_ACKNOWLEDGE_DISCLAIMER, () => ({
  db: R.dissocPath(['login', 'disclaimerAcknowledged'])
}))

regEventFx(evt.MFA_AUTH_REQUEST, ({ db }, _, __) => {
  const loginForm = getLoginForm(db)
  if (R.isEmpty(loginForm.realm)) {
    return
  }
  let payload = R.pick(['username', 'passcode'], loginForm)
  return {
    db: R.assocPath(['forms', 'login', 'requesting'], true),
    dispatch2: [
      evt.API_REQUEST,
      {
        method: 'post',
        url: '/authBrokerUserMFA',
        data: payload,
        success: success(evt.MFA_AUTH_REQUEST),
        failure: failure(evt.MFA_AUTH_REQUEST)
      }
    ]
  }
})

regEventFx(success(evt.MFA_AUTH_REQUEST), ({ db }, _, res) => {
  let loginForm = rename({ passcode: 'password' }, getLoginForm(db))
  delete loginForm.requesting
  if (R.path(['data', 'state'], res)) {
    loginForm.state = res.data.state
    loginForm.replyMessage = res.data.replyMessage
    return {
      db: R.assocPath(['forms', 'login'], loginForm)
    }
  }
  // call ldap auth, rename passcode to password
  loginForm.mfaToken = R.path(['data', 'token'], res)
  return {
    db: R.assocPath(['forms', 'login'], loginForm),
    dispatch2: [evt.LOGIN_REQUEST]
  }
})

regEventFx(failure(evt.MFA_AUTH_REQUEST), ({ db }, _, __) => {
  return {
    db: R.pipe(
      R.dissocPath(['forms', 'login', 'requesting']),
      R.assocPath(
        ['forms', 'login', 'errors', 'passcode'],
        'Authentication failed'
      )
    )
  }
})

regEventFx(evt.MFA_TOKEN_REQUEST, ({ db }, _, __) => {
  const loginForm = getLoginForm(db)
  if (R.isEmpty(loginForm.realm)) {
    return
  }
  let payload = R.pick(['username', 'passcode', 'state'], loginForm)
  return {
    db: R.pipe(
      R.assocPath(['forms', 'login', 'requesting'], true),
      R.dissocPath(['forms', 'login', 'errors'])
    ),
    dispatch2: [
      evt.API_REQUEST,
      {
        method: 'post',
        url: '/authBrokerUserMFA',
        data: payload,
        success: success(evt.MFA_TOKEN_REQUEST),
        failure: failure(evt.MFA_TOKEN_REQUEST)
      }
    ]
  }
})

regEventFx(success(evt.MFA_TOKEN_REQUEST), ({ db }, _, res) => {
  // call ldap auth, rename passcode to password
  let loginForm = R.omit(['secret'], getLoginForm(db))
  delete loginForm.requesting
  loginForm.passcode = ''
  if (R.pathEq(['data', 'code'], 'Access-Challenge', res)) {
    loginForm.state = res.data.state
    loginForm.replyMessage = res.data.replyMessage
    return {
      db: R.assocPath(['forms', 'login'], loginForm)
    }
  }
  loginForm.mfaToken = R.path(['data', 'token'], res)
  return {
    db: R.pipe(
      R.assocPath(['forms', 'login'], loginForm),
      R.assocPath(['forms', 'login', 'passcode'], '')
    ),
    dispatch2: [evt.LOGIN_REQUEST]
  }
})

regEventFx(failure(evt.MFA_TOKEN_REQUEST), ({ db }, _, __) => {
  return {
    db: R.pipe(
      R.dissocPath(['forms', 'login', 'requesting']),
      R.assocPath(
        ['forms', 'login', 'errors', 'passcode'],
        'Token verification failed'
      )
    )
  }
})
