import React from 'react'

export class Caret extends React.PureComponent {
  render() {
    return (
      <svg height="6px" width="10px" viewBox="0 0 10 6">
        <g fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-314.000000, -38.000000)">
            <path
              fill={this.props.fill || 'inherit'}
              d="M314.742187,39.078125 C314.624999,38.947916 314.602213,38.8014331 314.673828,38.6386719 C314.745443,38.4759106 314.872395,38.3945312 315.054687,38.3945312 L322.945312,38.3945312 C323.127605,38.3945312 323.254557,38.4759106 323.326172,38.6386719 C323.397787,38.8014331 323.375001,38.947916 323.257812,39.078125 L319.302734,43.8535156 C319.218099,43.9511724 319.113933,44 318.990234,44 C318.866536,44 318.76237,43.9511724 318.677734,43.8535156 L314.742187,39.078125 Z"
            />
          </g>
        </g>
      </svg>
    )
  }
}
