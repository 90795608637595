import { component } from 'framework-x'
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Div } from '../utils/styled-utils'
import { appAlerts } from '../app-alerts/subs'
import { AppAlerts } from '../app-alerts/views'
import { brokerTheme } from '../configuration/subs'
import { Guests } from '../guests/views'
import { Login } from '../login/views'
import { routeIds } from '../routes'
import { getRouteId } from '../subs'
import { isEmpty } from '../util'
import { createSub } from '../utils'
import { Header } from './Header'
import * as R from 'ramda'

const AllDialogs = component(
  'AllDialogs',
  createSub({
    appAlerts
  }),
  ({ appAlerts }) => (
    <Div
      className="hive-all-dialogs"
      css={{
        maxHeight: 0
      }}
    >
      {!isEmpty(appAlerts) && <AppAlerts alerts={appAlerts} />}
    </Div>
  )
)

const App = component(
  'App',
  createSub({
    getRouteId,
    brokerTheme,
    title: R.path(['configuration', 'title'])
  }),
  ({ brokerTheme, routeId, title }) => {
    document.title = title
    if (routeId === routeIds.LOGIN || routeId === routeIds.FORGOT_PASSWORD) {
      return <Login />
    }

    return (
      <Div
        className="hive-app"
        css={{
          width: '100vw !important',
          height: '100vh !important',
          overflow: 'auto !important',
          backgroundColor:
            routeId === routeIds.LOGIN
              ? brokerTheme.backgroundColor
              : brokerTheme.whitish
        }}
      >
        <Header
          css={{
            width: '100vw'
          }}
        />
        {(() => {
          if (routeId === routeIds.LOGIN) return <Login />
          if (routeId === routeIds.GUESTS) return <Guests />
          return <Div>Route not found</Div>
        })()}

        <AllDialogs />
      </Div>
    )
  }
)
export default App
