import { createStore } from 'framework-x'

const store = createStore()
// const store = createStore()
window._store = store

export const {
  dispatch,
  regEventFx,
  regFx,
  getState,
  setState,
  subscribeToState,
  connect
} = store
