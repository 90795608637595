import { Svg } from '../utils/styled-utils'
import React from 'react'

export const ConnectIcon = ({ ...css }) => {
  return (
    <Svg {...css} width="16px" height="16px" viewBox="0 0 16 16">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-904.000000, -349.000000)" fill="#FFFFFF">
          <g transform="translate(878.000000, 191.000000)">
            <g transform="translate(16.000000, 147.000000)">
              <path d="M11.2857143,12.2857143 L11.2857143,22.2678571 L24.7142857,22.2678571 L24.7142857,12.2857143 L11.2857143,12.2857143 Z M24.7142857,11 C25.0833352,11.0119048 25.3898797,11.1369036 25.6339286,11.375 C25.8779774,11.6130964 26,11.9166648 26,12.2857143 L26,22.2678571 C26,22.6369066 25.8779774,22.9434512 25.6339286,23.1875 C25.3898797,23.4315488 25.0833352,23.5535714 24.7142857,23.5535714 L11.2857143,23.5535714 C10.9166648,23.5535714 10.6130964,23.4315488 10.375,23.1875 C10.1369036,22.9434512 10.0119048,22.6369066 10,22.2678571 L10,12.2857143 C10.0119048,11.9166648 10.1369036,11.6130964 10.375,11.375 C10.6130964,11.1369036 10.9166648,11.0119048 11.2857143,11 L24.7142857,11 Z M25.3571429,26.1428571 L10.6428571,26.1428571 C10.45238,26.1428571 10.2976196,26.0833339 10.1785714,25.9642857 C10.0595232,25.8452375 10,25.6904771 10,25.5 C10,25.3214277 10.0595232,25.1726196 10.1785714,25.0535714 C10.2976196,24.9345232 10.45238,24.8690477 10.6428571,24.8571429 L25.3571429,24.8571429 C25.54762,24.8690477 25.7023804,24.9345232 25.8214286,25.0535714 C25.9404768,25.1726196 26,25.3214277 26,25.5 C26,25.6904771 25.9404768,25.8452375 25.8214286,25.9642857 C25.7023804,26.0833339 25.54762,26.1428571 25.3571429,26.1428571 Z" />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}
