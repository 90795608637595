function defaultEqualityCheck(a, b) {
  return a === b
}

function areArgumentsShallowlyEqual(equalityCheck, prev, next) {
  if (prev === null || next === null || prev.length !== next.length) {
    return false
  }

  // Do this in a for loop (and not a `forEach` or an `every`) so we can determine equality as fast as possible.
  const length = prev.length
  for (let i = 0; i < length; i++) {
    if (!equalityCheck(prev[i], next[i])) {
      return false
    }
  }

  return true
}

function hash(obj) {
  return JSON.stringify(obj)
}

export function defaultMemoize(func) {
  let lastArgs = null
  let lastResult = null
  // we reference arguments instead of spreading them for performance reasons
  return function() {
    const logIt = func.selector && func.selector.logIt
    const name = func.selector ? func.selector.selectorName : null
    const useValueHash = func.selector ? func.selector.useValueHash : null
    // const start = performance.now()
    let args = useValueHash ? hash(arguments) : arguments
    const is = useValueHash
      ? (a, b) => a === b
      : (a, b) => areArgumentsShallowlyEqual(defaultEqualityCheck, a, b)
    if (logIt) {
      // console.log(`${name} hash: ${performance.now() - start}ms`)
    }
    if (!is(lastArgs, args)) {
      if (logIt) {
        console.log('failed equality check', name, {
          lastArgs,
          args
        })
      }
      // apply arguments instead of spreading for performance.
      lastResult = func.apply(null, arguments)
    } else {
      if (logIt) console.log(`${name} NO OP`)
    }

    lastArgs = args
    return lastResult
  }
}

function getDependencies(funcs) {
  const dependencies = Array.isArray(funcs[0]) ? funcs[0] : funcs

  if (!dependencies.every(dep => typeof dep === 'function')) {
    const dependencyTypes = dependencies.map(dep => typeof dep).join(', ')
    throw new Error(
      'Selector creators expect all input-selectors to be functions, ' +
        `instead received the following types: [${dependencyTypes}]`
    )
  }

  return dependencies
}

export function createSelectorCreator(memoize, ...memoizeOptions) {
  return (...funcs) => {
    let recomputations = 0
    const resultFunc = funcs.pop()
    if (!resultFunc)
      throw new Error(
        'Expected createSelector to supply a "resultFunc" at the end'
      )
    const dependencies = getDependencies(funcs)

    let selector

    function recompute() {
      recomputations++
      if (selector.logIt && selector.selectorName) {
        console.log('recomputing', selector.selectorName)
      }
      // apply arguments instead of spreading for performance.
      return resultFunc.apply(null, arguments)
    }

    const memoizedResultFunc = memoize(recompute, ...memoizeOptions)

    // If a selector is called with the exact same arguments we don't need to traverse our dependencies again.
    selector = memoize(function() {
      const params = []
      const length = dependencies.length

      for (let i = 0; i < length; i++) {
        // apply arguments instead of spreading and mutate a local list of params for performance.
        params.push(dependencies[i].apply(null, arguments))
      }

      // apply arguments instead of spreading for performance.
      // if (selector.selectorName) {
      //   console.log('fetching value from memoized result func', selector.selectorName, params)
      // }
      return memoizedResultFunc.apply(null, params)
    })
    recompute.selector = selector
    selector.resultFunc = resultFunc
    selector.memoizedResultFunc = memoizedResultFunc
    selector.dependencies = dependencies
    selector.recomputations = () => recomputations
    selector.resetRecomputations = () => (recomputations = 0)
    return selector
  }
}

export const createSelector = createSelectorCreator(defaultMemoize)

export function createStructuredSelector(
  selectors,
  selectorCreator = createSelector
) {
  if (typeof selectors !== 'object') {
    throw new Error(
      'createStructuredSelector expects first argument to be an object ' +
        `where each property is a selector, instead received a ${typeof selectors}`
    )
  }
  const objectKeys = Object.keys(selectors)
  return selectorCreator(objectKeys.map(key => selectors[key]), (...values) => {
    return values.reduce((composition, value, index) => {
      composition[objectKeys[index]] = value
      return composition
    }, {})
  })
}
