// import chroma from 'chroma'
import color from 'color'
import { component, createSub } from 'framework-x'
/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Div, Input } from '../../utils/styled-utils'
import * as R from 'ramda'
import { Component } from 'react'
import { Tooltip } from '../../components/Tooltip'
import { brokerTheme } from '../../configuration/subs'
import {
  InputError,
  ToggleOff,
  ToggleOn,
  TooltipArrowLeft,
  TooltipEnd
} from '../../icons'
import theme from '../theme'
import MaterialIcon from 'material-icons-react'

export const H1 = styled.div({
  fontWeight: theme.fontWeightBold,
  fontSize: theme.fontSizeVeryLarge
})

export const FormTitle = styled.div({
  width: '100%',
  textAlign: 'center',
  fontWeight: theme.fontWeightBold,
  fontSize: theme.fontSizeVeryLarge,
  paddingBottom: 32 - 24
})

export const FormPropertySection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
})
export const FormPropertyLabel = styled.div({
  fontWeight: theme.fontWeightMedium,
  fontSize: theme.fontSizeMedium,
  paddingBottom: 6
})

export const RequiredFormPropertyLabel = ({ children }) => (
  <Div
    css={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}
  >
    <FormPropertyLabel>{children}</FormPropertyLabel>
    <Required />
  </Div>
)

export const TextInput = component(
  'BrokerInput',
  createSub({ brokerTheme }),
  ({ brokerTheme, css, ...rest }) => {
    return (
      <Input
        css={R.merge(
          {
            appearance: 'none',
            borderStyle: 'none',
            fontFamily: theme.defaultFontFamily,
            fontSize: theme.fontSizeSmall,
            backgroundColor: theme.inputBackgroundColor,
            height: 32,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 12,
            border: theme.inputBorder,
            transition: theme.borderTransition,
            '&:focus': {
              border: R.prop('mainColor', brokerTheme)
                ? `1px solid ${brokerTheme.mainColor}`
                : theme.inputFocusBorder,
              outline: 'none'
            },
            '&::placeholder': {
              color: theme.placeholderColor
            }
          },
          css
        )}
        {...rest}
      />
    )
  }
)

export const Required = () => (
  <Div
    css={{
      textTransform: 'uppercase',
      fontSize: 10,
      fontWeight: theme.fontWeightRegular,
      letterSpacing: '0.8px'
    }}
  >
    Required
  </Div>
)

export class TextInputWithErrorsInner extends Component {
  state = { hovered: null }

  render() {
    let {
      id,
      css,
      type,
      value,
      errors,
      //isRequired,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      disabled,
      placeholder,
      brokerTheme,
      ...rest
    } = this.props
    const { hovered } = this.state

    const togglePasswordVisibility = () => {
      const input = document.querySelector('#loginPassword')
      const eye = document.querySelector('#loginEye')
      if (input.type === 'password') {
        input.type = 'text'
        eye.innerHTML = 'visibility_off'
      } else {
        input.type = 'password'
        eye.innerHTML = 'visibility'
      }
    }

    return (
      <Tooltip
        component={
          !(errors && hovered) ? (
            <Div css={{ height: 28 }} />
          ) : (
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 8
              }}
            >
              <TooltipArrowLeft />
              <Div
                css={{
                  color: '#fff',
                  width: '100%',
                  height: 28,
                  fontWeight: theme.fontWeightMedium,
                  fontSize: theme.fontSizeVerySmall,
                  background: '#2A2A2A',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {errors}
              </Div>
              <TooltipEnd />
            </Div>
          )
        }
      >
        <Div
          className="input-container"
          css={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            border: errors ? theme.inputErrorBorder : theme.inputBorder,
            borderRadius: '3px',
            paddingRight: errors ? '36px' : '12px',
            backgroundColor: theme.inputBackgroundColor,
            transition: theme.borderTransition,
            '&:focus-within': !errors && {
              border: theme.inputFocusBorder,
              outline: 'none'
            }
          }}
        >
          <Input
            value={value || ''}
            {...{ id, type, onChange, onFocus, onBlur, onKeyDown, disabled }}
            placeholder={disabled ? null : placeholder}
            css={R.merge(
              {
                width: '100%',
                appearance: 'none',
                borderStyle: 'none',
                fontFamily: theme.defaultFontFamily,
                fontSize: theme.fontSizeSmall,
                backgroundColor: theme.inputBackgroundColor,
                height: 32,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 12,
                paddingRight: 12,
                '&:focus': {
                  outline: 'none'
                },
                '&::placeholder': {
                  color: theme.placeholderColor
                }
              },
              css
            )}
            {...rest}
          />
          {id === 'loginPassword' && (
            <MaterialIcon
              id="loginEye"
              onClick={togglePasswordVisibility}
              icon="visibility"
              color={theme.placeholderGray}
            />
          )}
          {errors && (
            <Div
              css={{
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex'
              }}
              onMouseEnter={() => this.setState({ hovered: true })}
              onMouseLeave={() => this.setState({ hovered: null })}
            >
              <InputError />
            </Div>
          )}
        </Div>
      </Tooltip>
    )
  }
}

export const TextInputWithErrors = component(
  'BrokerTextInputWithErrors',
  createSub({ brokerTheme }),
  ({ ...props }) => <TextInputWithErrorsInner {...props} />
)

export const Textarea = styled.textarea({
  appearance: 'none',
  borderStyle: 'none',
  fontFamily: theme.defaultFontFamily,
  fontSize: theme.fontSizeSmall,
  backgroundColor: theme.inputBackgroundColor,
  padding: 12,
  border: theme.inputBorder,
  resize: 'none',
  transition: theme.borderTransition,
  '&:focus': {
    border: theme.inputFocusBorder,
    outline: 'none'
  },
  '&::placeholder': {
    color: theme.placeholderColor
  }
})
export const FormPropertyDescription = styled.div({
  paddingTop: 6,
  fontSize: theme.fontSizeVerySmall
})

export const OptionButtons = styled.div({
  display: 'flex',
  '&>*': {
    flex: 1
  }
  // height: 32,
})
const NO_OP = () => null
export const OptionButton = component(
  'OptionButton',
  ({ active, children, css, onClick = NO_OP, ...rest }) => {
    let _el = null
    const backgroundColor = active
      ? theme.activeButtonBackgroundColor
      : theme.buttonBackgroundColor
    return (
      <Div
        ref={el => (_el = el)}
        role="button"
        tabIndex={0}
        onKeyPress={e => {
          if (e.which === 32 || e.which === 13) {
            onClick(e)
          }
        }}
        onClick={e => {
          _el.blur()
          onClick(e)
        }}
        css={{
          fontSize: theme.fontSizeSmall,
          cursor: 'pointer',
          userSelect: 'none',
          height: 32,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'background-color 200ms',
          '&:focus': {
            outline: 'none',
            backgroundColor: color(backgroundColor)
              .darken(0.1)
              .string()
          },
          '&:hover': {
            backgroundColor: color(backgroundColor)
              .darken(0.05)
              .string()
          },
          '&:active': {
            backgroundColor: `${color(backgroundColor)
              .darken(0.2)
              .string()}!important`
          },
          '&>*:not(:first-child)': {
            paddingLeft: 10
          },
          backgroundColor,
          color: active ? theme.activeButtonColor : theme.buttonColor,
          /* color child icons to match */
          '&>svg': {
            fill: `${
              active ? theme.activeButtonColor : theme.buttonColor
            }!important`
          },
          ...css
        }}
        {...rest}
      >
        {children}
      </Div>
    )
  }
)

export const SideBySideControls = styled.div({
  display: 'flex',
  '&>*': {
    flex: 1
  },
  '&>*:not(:first-child)': {
    marginLeft: 20
  },
  '&>*:not(:last-child)': {
    marginRight: 20
  }
})

export const SideBySideControlsInset = styled.div({
  display: 'flex',
  background: theme.darkerGray,
  padding: 20,
  '&>*': {
    flex: 1
  },
  '&>*:not(:first-child)': {
    marginLeft: 20
  },
  '&>*:not(:last-child)': {
    marginRight: 20
  }
})

/* Optional reusable field component */
export const SimpleBoundTextField = ({
  required,
  title,
  placeholder,
  description,
  targetProp,
  change,
  value,
  disabled,
  type = 'text',
  ...rest
}) => (
  <FormPropertySection>
    {required ? (
      <RequiredFormPropertyLabel>{title}</RequiredFormPropertyLabel>
    ) : (
      <FormPropertyLabel>{title}</FormPropertyLabel>
    )}
    <TextInput
      placeholder={placeholder}
      disabled={disabled}
      type={type}
      value={value || rest[targetProp] || ''}
      onChange={e => change(targetProp, e.target.value)}
    />
    {description && (
      <FormPropertyDescription>{description}</FormPropertyDescription>
    )}
  </FormPropertySection>
)

export const Toggle = ({ css, value, onChange }) => {
  return (
    <Div
      css={R.merge({ cursor: 'pointer' }, css)}
      onClick={() => onChange(!value)}
    >
      {value ? <ToggleOn /> : <ToggleOff />}
    </Div>
  )
}
