import { component, createSub } from 'framework-x'
import * as R from 'ramda'
import React from 'react'
/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Div } from '../../utils/styled-utils'
import { brokerTheme } from '../../configuration/subs'
import { evt } from '../../eventTypes'
import { dispatch } from '../../store'
import { isEmpty } from '../../util'
import theme, { unstyleButton } from '../theme'
import { SmallSpinner } from '../../icons/SmallSpinner'
import color from 'color'

export const Button = styled.button({
  ...unstyleButton,
  borderRadius: 6,
  paddingLeft: 16,
  paddingRight: 16,
  height: 36,
  fontSize: theme.fontSizeSmall,
  fontWeight: theme.fontWeightMedium,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:disabled': {
    width: '100%',
    opacity: 0.7,
    cursor: 'inherit'
  }
})

export const PrimaryButton = component(
  'ThemedPrimaryButton',
  createSub({ brokerTheme }),
  ({ inverse, children, onClick, disabled, busy, brokerTheme, ...props }) => {
    const backgroundColor = inverse
      ? brokerTheme.buttonTextColor
      : brokerTheme.mainColor
    const textColor = inverse
      ? brokerTheme.backgroundColor
      : brokerTheme.buttonTextColor
    if (typeof busy !== 'undefined') {
      disabled = disabled || busy
      if (busy) {
        if (!Array.isArray(children)) children = [children]
        children = [
          ...children,
          <SmallSpinner
            key={`${children.count + 1}`}
            css={{ marginLeft: 12 }}
          />
        ]
      }
    }
    return (
      <Button
        css={{
          fontWeight: theme.fontWeightSemiBold,
          backgroundColor,
          color: textColor,
          transition: 'background 0.25s',
          '&:hover': {
            backgroundColor: color(backgroundColor).darken(0.2)
          },
          minWidth: 120
        }}
        {...props}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </Button>
    )
  }
)

export const ActionButton = component(
  'ThemedActionButton',
  createSub({ brokerTheme }),
  ({ inverse, children, onClick, disabled, busy, brokerTheme, ...props }) => {
    const backgroundColor = inverse
      ? brokerTheme.buttonTextColor
      : brokerTheme.mainColor
    const textColor = inverse
      ? brokerTheme.backgroundColor
      : brokerTheme.buttonTextColor
    if (typeof busy !== 'undefined') {
      disabled = disabled || busy
      if (busy) {
        if (!Array.isArray(children)) children = [children]
        children = [
          ...children,
          <SmallSpinner key={`${children.count + 1}`} css={{ marginLeft: 4 }} />
        ]
      }
    }
    return (
      <Button
        css={{
          paddingLeft: 4,
          paddingRight: 4,
          fontWeight: theme.fontWeightSemiBold,
          backgroundColor,
          color: textColor,
          border: '1px solid',
          borderColor: theme.lightAccent,
          transition: 'background 0.25s',
          '&:hover:enabled': {
            opacity: 0.7
          },
          minWidth: 75
        }}
        {...props}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </Button>
    )
  }
)

export const SecondaryButton = component(
  'ThemedSecondaryButton',
  createSub({ brokerTheme }),
  ({
    inverse,
    children,
    onClick,
    disabled,
    brokerTheme,
    backgroundTheme,
    backgroundColor,
    textTheme,
    textColor,
    ...props
  }) => {
    if (!backgroundColor) {
      if (!!backgroundTheme) {
        backgroundColor = brokerTheme[backgroundTheme]
      } else {
        backgroundColor = inverse
          ? brokerTheme.buttonTextColor
          : brokerTheme.backgroundColor
      }
    }
    if (!textColor) {
      if (!!textTheme) {
        textColor = brokerTheme[backgroundColor]
      } else {
        textColor = inverse
          ? brokerTheme.backgroundColor
          : brokerTheme.buttonTextColor
      }
    }

    return (
      <Button
        css={{
          fontWeight: theme.fontWeightSemiBold,
          backgroundColor: backgroundColor,
          color: textColor,
          border: `2px solid ${textColor}`,
          transition: 'background 0.25s',
          '&:hover:not([disabled])': {
            backgroundColor: backgroundColor,
            color: color(textColor).darken(0.2),
            border: `2px solid ${color(textColor).darken(0.2)}`
          }
        }}
        {...props}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </Button>
    )
  }
)

const LinkText = styled.div({
  color: theme.lightAccent,
  ':hover': { textDecoration: 'underline' }
})

// Note: This is not a button
export const A = ({ routeId, params, children, ...props }) => {
  let children$ =
    typeof children === 'string' ? <LinkText>{children}</LinkText> : children
  return (
    <Div
      css={R.merge({ cursor: 'pointer' }, props)}
      onClick={() => dispatch(evt.NAV_TO, [routeId, params])}
    >
      {children$}
    </Div>
  )
}

export class FileUploadButton extends React.Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
  }

  clickInput() {
    this.input.current.files = null
    this.input.current.click()
  }

  handleChange(e) {
    const fd = new FormData()
    // may need "datafile" for appliance firmware image
    fd.append('file', e.target.files[0])
    this.props.onChange(fd)
  }

  render() {
    const { children, label, accept } = this.props

    return (
      <Div>
        <input
          style={{ display: 'none' }}
          type={'file'}
          ref={this.input}
          accept={accept}
          onChange={e => this.handleChange(e)}
        />
        {!isEmpty(children) ? (
          React.Children.map(children, child =>
            React.cloneElement(child, {
              onClick: () => this.clickInput()
            })
          )
        ) : (
          <PrimaryButton onClick={() => this.clickInput()}>
            {label}
          </PrimaryButton>
        )}
      </Div>
    )
  }
}
