/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { Div } from '../../utils/styled-utils'
import PropTypes from 'prop-types'
import React, { cloneElement, Component } from 'react'
import { Caret } from '../../icons/Caret'
import theme from '../theme'
import { Tooltip } from '../../components/Tooltip'
import { InputError } from '../../icons/InputError'
import { TooltipArrowLeft } from '../../icons/TooltipArrowLeft'
import { TooltipEnd } from '../../icons/TooltipEnd'

const { any, object, string, bool, func } = PropTypes

const Relative = styled.div({
  position: 'relative'
})

const DropdownRoot = styled.div(
  {
    position: 'absolute',
    zIndex: 1
    //  TODO. Figure out how to pass arguments to this so we
    // can optionally allow overflow when we have a long list of items
    // maxHeight: '288px',
    // overflowY: 'auto'
  },
  ({ open }) => {
    const moveUp = keyframes`({
      '0%': {
        marginTop: 10
      },
      '100%': {
        marginTop: 0
      }
    })`

    const fadeIn = keyframes`({
      '0%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    })`

    if (!open) {
      return {
        display: 'none',
        opacity: 0
      }
    }
    return { animation: `${fadeIn} 300ms ease 1, ${moveUp} 300ms ease-out 1` }
  }
)

export class SearchDropdownContainer extends Component {
  static propTypes = {
    css: any,
    dropdownCss: object,
    children: any.isRequired,
    button: any.isRequired,
    className: string,
    closeOnClick: bool,
    subscribeToChildKeyDown: func,
    isHover: bool,
    disabled: bool,
    onEnter: any,
    autoFocus: bool
  }
  state = { open: false, search: '', searchTimer: null }

  componentDidMount() {
    this.props.subscribeToChildKeyDown &&
      this.props.subscribeToChildKeyDown(this.onKeyDown)
    !this.props.isHover &&
      window.addEventListener('click', this.pageClick, true)
    this.props.autoFocus && this.focus()
  }

  componentDidUpdate() {
    this.props.subscribeToChildKeyDown &&
      this.props.subscribeToChildKeyDown(this.onKeyDown)
  }

  componentWillUnmount() {
    !this.props.isHover &&
      window.removeEventListener('click', this.pageClick, true)
    if (!!this.state.searchTimer) {
      clearTimeout(this.state.searchTimer)
    }
  }

  pageClick = e => {
    if (this.props.closeOnClick && !this.buttonEl.contains(e.target)) {
      return this.close()
    }
    if (!this.rootEl.contains(e.target)) {
      return this.close()
    }
  }

  toggle = () => (!this.state.open ? this.open() : this.close())

  open = () => {
    this.setState({ open: true })
  }

  close = () => {
    if (!!this.state.searchTimer) {
      clearTimeout(this.state.searchTimer)
    }
    this.setState({ open: false, search: '', searchTimer: null })
    ;[...this.rootEl.querySelectorAll('*[tabindex]')].forEach(
      child => (child.hidden = false)
    )
  }

  focus = () => {
    const allFocusable = [
      ...this.rootEl.querySelectorAll('*[tabindex]')
    ].filter(child => !child.hidden)
    allFocusable[0].focus()
  }

  onKeyDown = e => {
    if (e.which === 9) {
      //TAB out
      this.close()
      return
    }
    if (!this.state.open) {
      if (!!this.props.onEnter && e.which === 13) {
        this.props.onEnter(e)
        return
      }
      if (
        e.which === 32 ||
        e.which === 13 ||
        e.which === 40 ||
        /^[a-zA-Z0-9\\.-]$/.test(e.key)
      ) {
        this.open()
      } else {
        return
      }
    }
    /* OPEN */
    if (e.which === 27) {
      this.close()
      this.setState({ search: '' })
      e.preventDefault()
      e.stopPropagation()
      return false
    }
    const focused = this.rootEl.querySelector(':focus')
    if ((e.which === 32 || e.which === 13) && focused === this.buttonEl) {
      this.close()
      return
    }
    const allFocusable = [
      ...this.rootEl.querySelectorAll('*[tabindex]')
    ].filter(child => !child.hidden)
    const focusIndex = allFocusable.indexOf(focused)
    /* Navigating in list */
    if (e.which === 32 || e.which === 13) {
      e.preventDefault()
      //find child with focus...
      focused.click()
      this.rootEl.focus()
      return
    }
    if (e.which === 40) {
      e.preventDefault()
      const next = allFocusable[focusIndex + 1]
      if (next) {
        next.focus()
      }
      return
    }
    if (e.which === 38) {
      e.preventDefault()
      if (focusIndex < 2) return
      const next = allFocusable[focusIndex - 1]
      if (next) {
        next.focus()
      }
      return
    }
    let { search, searchTimer } = this.state
    if (!searchTimer) {
      clearTimeout(searchTimer)
    }
    searchTimer = setTimeout(() => {
      this.rootEl &&
        [...this.rootEl.querySelectorAll('*[tabindex]')].forEach(
          child => (child.hidden = false)
        )
      this.setState({ search: '', searchTimer: null })
    }, 5000)
    if (/^[a-zA-Z0-9\\.-]$/.test(e.key)) {
      search += e.key
    }
    if (e.key === 'Backspace') {
      search = search.slice(0, -1)
    }
    e.preventDefault()
    let visible = []
    let first = true
    ;[...this.rootEl.querySelectorAll('*[tabindex]')].forEach(child => {
      if (first || child.textContent.length === 0) {
        first = false
        return
      }
      if (
        !search ||
        child.textContent.toLowerCase().indexOf(search.toLowerCase()) > -1
      ) {
        visible.push(child)
        child.hidden = false
      } else child.hidden = true
    })
    if (visible.length > 0) {
      const focused = visible[0]
      focused.focus()
      if (visible.length === 1) focused.click()
    } else {
      this.close()
      return this.rootEl.childNodes[0].focus()
    }
    this.setState({ search })
  }

  render() {
    const {
      dropdownCss,
      children,
      button,
      className,
      isHover,
      closeOnClick,
      disabled,
      ...css
    } = this.props
    const { open } = this.state

    return (
      <Relative
        ref={c => (this.rootEl = c)}
        {...css}
        className={className}
        onMouseLeave={() => isHover && this.close()}
      >
        {cloneElement(button, {
          ref: c => (this.buttonEl = c),
          onClick: () => !disabled && !isHover && this.toggle(),
          onKeyDown: e => !disabled && this.onKeyDown(e),
          onMouseEnter: () => !disabled && isHover && this.open()
        })}

        <DropdownRoot
          open={open}
          css={dropdownCss}
          onClick={() => !disabled && isHover && closeOnClick && this.close()}
        >
          {children}
        </DropdownRoot>
      </Relative>
    )
  }
}

export class DropdownContainer extends Component {
  static propTypes = {
    css: any,
    dropdownCss: object,
    children: any.isRequired,
    button: any.isRequired,
    className: string,
    closeOnClick: bool,
    subscribeToChildKeyDown: func,
    isHover: bool,
    disabled: bool
  }
  state = { open: false }

  componentDidMount() {
    this.props.subscribeToChildKeyDown &&
      this.props.subscribeToChildKeyDown(this.onKeyDown)
    !this.props.isHover &&
      window.addEventListener('click', this.pageClick, true)
  }

  componentDidUpdate() {
    this.props.subscribeToChildKeyDown &&
      this.props.subscribeToChildKeyDown(this.onKeyDown)
  }

  componentWillUnmount() {
    !this.props.isHover &&
      window.removeEventListener('click', this.pageClick, true)
  }

  pageClick = e => {
    if (this.props.closeOnClick && !this.buttonEl.contains(e.target)) {
      return this.close()
    }
    if (!this.rootEl.contains(e.target)) {
      return this.close()
    }
  }

  toggle = () => (!this.state.open ? this.open() : this.close())

  open = () => {
    this.setState({ open: true })
  }

  close = () => this.setState({ open: false })

  onKeyDown = e => {
    if (e.which === 9) {
      //TAB out
      this.close()
      return
    }
    if (!this.state.open) {
      if (e.which === 32 || e.which === 13 || e.which === 40) {
        this.open()
      }
      return
    }
    /* OPEN */
    if (e.which === 27) {
      this.close()
      return
    }
    const focused = this.rootEl.querySelector(':focus')
    if ((e.which === 32 || e.which === 13) && focused === this.buttonEl) {
      this.close()
      return
    }
    const allFocusable = [...this.rootEl.querySelectorAll('*[tabindex]')]
    const focusIndex = allFocusable.indexOf(focused)
    /* Navigating in list */
    if (e.which === 32 || e.which === 13) {
      e.preventDefault()
      //find child with focus...
      focused.click()
    }
    if (e.which === 40) {
      e.preventDefault()
      const next = allFocusable[focusIndex + 1]
      if (next) {
        next.focus()
      }
    }
    if (e.which === 38) {
      e.preventDefault()
      if (focusIndex < 2) return
      const next = allFocusable[focusIndex - 1]
      if (next) {
        next.focus()
      }
    }
  }

  render() {
    const {
      dropdownCss,
      children,
      button,
      className,
      isHover,
      closeOnClick,
      disabled,
      ...css
    } = this.props
    const { open } = this.state

    return (
      <Relative
        ref={c => (this.rootEl = c)}
        {...css}
        className={className}
        onMouseLeave={() => isHover && this.close()}
      >
        {cloneElement(button, {
          ref: c => (this.buttonEl = c),
          onClick: () => !disabled && !isHover && this.toggle(),
          onKeyDown: e => !disabled && this.onKeyDown(e),
          onMouseEnter: () => !disabled && isHover && this.open()
        })}

        <DropdownRoot
          open={open}
          css={dropdownCss}
          onClick={() => !disabled && isHover && closeOnClick && this.close()}
        >
          {children}
        </DropdownRoot>
      </Relative>
    )
  }
}

const SmallSpinner = () => {
  return (
    <svg width="14px" height="12px" viewBox="0 0 14 12">
      <defs>
        <linearGradient
          x1="50.3346784%"
          y1="33.6318736%"
          x2="59.1496938%"
          y2="84.4413596%"
          id="hive-small-spinner-gradient"
        >
          <stop stopColor="#7F898B" offset="0%" />
          <stop stopColor="#7F898B" stopOpacity="0" offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle
          id={'hive-spinner-small'}
          stroke="url(#hive-small-spinner-gradient)"
          cx="7"
          cy="7"
          r="6"
        />
      </g>
    </svg>
  )
}

const LoadingDropdownButton = ({ disabled = true }) => {
  return (
    <Div
      role="button"
      tabIndex={0}
      css={{
        backgroundColor: theme.inputBackgroundColor,
        padding: 0,
        paddingLeft: 8,
        paddingRight: 8,
        fontFamily: theme.defaultFontFamily,
        fontSize: 15,
        border: theme.inputBorder,
        color: disabled ? theme.dark : 'inherit',
        cursor: disabled ? 'inherit' : 'pointer',
        userSelect: 'none',
        transition: theme.borderTransition,
        '&:focus': {
          border: theme.inputFocusBorder,
          outline: 'none'
        }
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
          height: 30
        }}
      >
        <SmallSpinner />
        <Div
          css={{
            marginLeft: 10,
            width: '95%',
            height: '65%',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          Loading, please wait...
        </Div>
        <Caret />
      </Div>
    </Div>
  )
}

export class Dropdown extends Component {
  state = { hovered: null }

  render() {
    let {
      buttonText,
      multiSelect,
      children,
      disabled,
      loading,
      errors
    } = this.props
    let cb = () => {}
    let subscribeToChildKeyDown = listener => (cb = listener)
    const { hovered } = this.state
    return (
      <Tooltip>
        component=
        {!(errors && hovered) ? (
          <Div css={{ height: 28 }} />
        ) : (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: 8
            }}
          >
            <TooltipArrowLeft />
            <Div
              css={{
                color: '#fff',
                width: '100%',
                height: 28,
                fontWeight: theme.fontWeightMedium,
                fontSize: theme.fontSizeVerySmall,
                background: '#2A2A2A',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {errors}
            </Div>
            <TooltipEnd />
          </Div>
        )}
        >
        <Div css={{ position: 'relative' }}>
          <DropdownContainer
            subscribeToChildKeyDown={subscribeToChildKeyDown}
            closeOnClick={!multiSelect && !loading}
            disabled={disabled || loading}
            css={{
              width: errors ? 'calc(100% - 30px)' : '100%',
              border: errors ? theme.inputErrorBorder : theme.inputBorder,
              transition: theme.borderTransition,
              '&:focus': {
                border: errors
                  ? theme.inputErrorBorder
                  : theme.inputFocusBorder,
                outline: 'none'
              },
              height: 32
            }}
            dropdownCss={{ width: '100%' }}
            button={
              loading ? (
                <LoadingDropdownButton />
              ) : (
                <Div
                  role="button"
                  tabIndex={0}
                  css={{
                    backgroundColor: theme.inputBackgroundColor,
                    padding: 0,
                    paddingLeft: 8,
                    paddingRight: 8,
                    fontFamily: theme.defaultFontFamily,
                    fontSize: 15,
                    color: disabled ? theme.dark : 'inherit',
                    cursor: disabled ? 'inherit' : 'pointer',
                    userSelect: 'none',
                    transition: theme.borderTransition,
                    '&:focus': {
                      border: theme.inputFocusBorder,
                      outline: 'none'
                    }
                  }}
                >
                  <Div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flex: 1,
                      height: 30
                    }}
                  >
                    <Div
                      css={{
                        width: '95%',
                        height: '65%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {buttonText}
                    </Div>
                    {disabled ? <Div /> : <Caret />}
                  </Div>
                </Div>
              )
            }
          >
            <Div
              css={{
                display: 'flex',
                flexDirection: 'column',
                border: theme.inputBorder,
                backgroundColor: 'white',
                boxShadow: theme.dropdownShadow,
                overflowY: 'auto',
                maxHeight: 250
              }}
            >
              <Relative>
                {React.Children.map(children, child =>
                  React.cloneElement(child, {
                    onKeyDown: e => cb(e)
                  })
                )}
              </Relative>
            </Div>
          </DropdownContainer>
          {errors && !this.changed && (
            <Div
              css={{
                position: 'absolute',
                top: 0,
                right: 0,
                height: 30,
                display: 'flex'
              }}
              onMouseEnter={() => this.setState({ hovered: true })}
              onMouseLeave={() => this.setState({ hovered: null })}
            >
              <InputError />
            </Div>
          )}
        </Div>
      </Tooltip>
    )
  }
}

export class SearchDropdown extends Component {
  state = { hovered: null }

  render() {
    let {
      buttonText,
      multiSelect,
      children,
      disabled,
      loading,
      onEnter,
      errors,
      autoFocus
    } = this.props
    let cb = () => {}
    let subscribeToChildKeyDown = listener => (cb = listener)
    const { hovered } = this.state
    return (
      <Tooltip
        component={
          !(errors && hovered) ? (
            <Div css={{ height: 28 }} />
          ) : (
            <Div
              css={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 8
              }}
            >
              <TooltipArrowLeft />
              <Div
                css={{
                  color: '#fff',
                  width: '100%',
                  height: 28,
                  fontWeight: theme.fontWeightMedium,
                  fontSize: theme.fontSizeVerySmall,
                  background: '#2A2A2A',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {errors}
              </Div>
              <TooltipEnd />
            </Div>
          )
        }
      >
        <Div css={{ position: 'relative' }}>
          <SearchDropdownContainer
            subscribeToChildKeyDown={subscribeToChildKeyDown}
            closeOnClick={!multiSelect && !loading}
            disabled={disabled || loading}
            onEnter={onEnter}
            autoFocus={autoFocus}
            ref={this.props.fieldRef}
            css={{
              width: errors ? 'calc(100% - 30px)' : '100%',
              border: errors ? theme.inputErrorBorder : theme.inputBorder,
              transition: theme.borderTransition,
              '&:focus': {
                border: errors
                  ? theme.inputErrorBorder
                  : theme.inputFocusBorder,
                outline: 'none'
              },
              height: 32
            }}
            dropdownCss={{ width: '100%' }}
            button={
              loading ? (
                <LoadingDropdownButton />
              ) : (
                <Div
                  role="button"
                  tabIndex={0}
                  css={{
                    backgroundColor: theme.inputBackgroundColor,
                    padding: 0,
                    paddingLeft: 8,
                    paddingRight: 8,
                    fontFamily: theme.defaultFontFamily,
                    fontSize: 15,
                    color: disabled ? theme.dark : 'inherit',
                    cursor: disabled ? 'inherit' : 'pointer',
                    userSelect: 'none',
                    transition: theme.borderTransition,
                    '&:focus': {
                      border: theme.inputFocusBorder,
                      outline: 'none'
                    }
                  }}
                >
                  <Div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flex: 1,
                      height: 30
                    }}
                  >
                    <Div
                      css={{
                        width: '95%',
                        height: '65%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {buttonText}
                    </Div>
                    {disabled ? <Div /> : <Caret />}
                  </Div>
                </Div>
              )
            }
          >
            <Div
              css={{
                display: 'flex',
                flexDirection: 'column',
                border: theme.inputBorder,
                backgroundColor: 'white',
                boxShadow: theme.dropdownShadow,
                overflowY: 'auto',
                maxHeight: 250
              }}
            >
              <Relative>
                {React.Children.map(children, child =>
                  React.cloneElement(child, {
                    onKeyDown: e => cb(e)
                  })
                )}
              </Relative>
            </Div>
          </SearchDropdownContainer>
          {errors && !this.changed && (
            <Div
              css={{
                position: 'absolute',
                top: 0,
                right: 0,
                height: 30,
                display: 'flex'
              }}
              onMouseEnter={() => this.setState({ hovered: true })}
              onMouseLeave={() => this.setState({ hovered: null })}
            >
              <InputError />
            </Div>
          )}
        </Div>
      </Tooltip>
    )
  }
}

export class DropdownItem extends React.PureComponent {
  render() {
    const { css, active, ...rest } = this.props
    return (
      <Div
        ref={el => (this._el = el)}
        tabIndex={-1}
        {...rest}
        css={{
          cursor: 'pointer',
          minHeight: 40,
          wordBreak: 'break-all',
          fontSize: theme.fontSizeSmall,
          padding: 12,
          '&:hover': {
            backgroundColor: theme.inputBackgroundColor
          },
          '&:active': {
            backgroundColor: theme.activeButtonBackgroundColor,
            color: theme.white
          },
          '&:focus': {
            backgroundColor: theme.darkerGray,
            outline: 'none'
          },
          backgroundColor: active
            ? theme.activeButtonBackgroundColor
            : theme.white,
          color: active ? theme.white : 'inherit',
          ...css
        }}
      />
    )
  }
}
