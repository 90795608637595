import queryString from 'query-string'
import { pathOr, propOr } from 'ramda'
import { derive } from '../utils/reselect'
import * as R from 'ramda'

/* For loading indicators, if needed */
export const getInitialDataReady = propOr(false, 'initialDataReceived')
export const getIsConnected = propOr(false, 'connected')

/* Routing */
export const getRouteId = pathOr('', ['router', 'match', 'route', 'id'])
export const getRouteParams = pathOr({}, ['router', 'match', 'params'])
export const getSearchString = pathOr('', ['router', 'location', 'search'])
export const getRouterQuery = derive(getSearchString, search =>
  queryString.parse(search)
)

export const getHideHeader = derive(getRouterQuery, q => !R.prop('hideHeader', q))
