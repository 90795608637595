/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Div } from '../utils/styled-utils'
import { evt } from '../eventTypes'
import { CloseX } from '../icons/CloseX'
import { isEmpty } from '../util'
import theme from '../views/theme'
import { dispatch } from '../store'

export const AppAlerts = ({ alerts }) => {
  return (
    <Div
      css={{
        position: 'absolute',
        bottom: !isEmpty(alerts) ? '10vh' : 0,
        transition: 'bottom 1s',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 3
      }}
    >
      {alerts.map(({ message, type, id }, i) => {
        return (
          <Div
            key={i}
            css={{
              position: 'relative',
              borderRadius: 24,
              height: 48,
              minWidth: '40vw',
              maxWidth: '60vw',
              background: type === 'error' ? '#D05F5F' : '#A4D05F',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: 'white',
              paddingLeft: 30,
              paddingRight: 15,
              marginBottom: 15,
              fontWeight: theme.fontWeightSemiBold,
              fontSize: theme.fontSizeMedium,
              boxShadow: '0px 4px 11px -4px rgba(0,0,0,0.32)'
            }}
          >
            <Div />
            <Div
              css={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {message}
            </Div>
            <CloseX
              css={{
                justifySelf: 'flex-end',
                marginLeft: 15,
                cursor: 'pointer'
              }}
              width={14}
              height={14}
              fill={'#fff'}
              onClick={() => dispatch(evt.HIDE_ALERT, { id })}
            />
          </Div>
        )
      })}
    </Div>
  )
}
