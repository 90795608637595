import * as R from 'ramda'

export const isPlainObject = a => {
  return !!a && a.constructor === Object
}

export const isEmpty = x => R.isNil(x) || R.isEmpty(x)

export const first = coll =>
  Array.isArray(coll) || typeof coll === 'string' ? R.head(coll) : null

export const last = coll =>
  Array.isArray(coll) || typeof coll === 'string' ? R.last(coll) : null

export const mapIndexed = R.addIndex(R.map)

export const reduceKV = R.addIndex(R.reduce)

// Requires args as array because object rest spread
// and don't want to analyze `arguments` at the moment
/**
 * @deprecated
 * @param m
 * @param k
 * @param f
 * @param args
 */
export const update = (m, k, f, args) => {
  const fargs = [R.prop(k, m)].concat(args || [])

  return R.assoc(k, R.apply(f, fargs), m)
}

export const rename = R.curry((keysMap, obj) =>
  R.reduce(
    (acc, key) => R.assoc(keysMap[key] || key, obj[key], acc),
    {},
    R.keys(obj)
  )
)

export const threadLast = (arg, ...fs) => {
  return R.reduce((a, f) => f(a), R.head(fs)(arg), R.tail(fs))
}

export const update2 = R.curry((k, f, m) => R.assoc(k, f(R.prop(k, m)), m))

export const updateIn = R.curry((ks, f, m) =>
  R.assocPath(ks, f(R.path(ks, m)), m)
)

export const some = (f, coll) =>
  R.head(R.dropWhile(x => !!!x, R.map(f, coll))) || null

export const paramKeyToEntityKey = {
  host: 'hosts',
  guest: 'guests',
  storagePool: 'storagePools',
  guestPool: 'guestPools'
}

export const entityIdKey = entityType => {
  switch (entityType) {
    case 'guest':
    case 'guests':
      return 'uuid'

    case 'host':
    case 'hosts':
      return 'hostid'

    default:
      return 'id'
  }
}

export const entityById = (entityType, id) => db => {
  return R.path(['data', entityType, id], db)
}

export const entityNameKey = entityType => {
  switch (entityType) {
    case 'host':
    case 'hosts':
      return 'hostname'

    default:
      return 'name'
  }
}
