import { evt } from './eventTypes'

export const routeIds = {
  LOGIN: 'login',
  GUESTS: 'guests'
}

export const routes = [
  {
    id: routeIds.LOGIN,
    path: '/',
    onEnterAlways: db => {
      return [
        [evt.LOGIN_GET_INITIAL_REQUIRED_DATA],
        [evt.RESET_ACKNOWLEDGE_DISCLAIMER]
      ]
    }
  },
  {
    id: routeIds.GUESTS,
    path: '/guests',
    onEnterAlways: () => [evt.GET_ASSIGNMENTS]
  }
]

export const unprotectedRoutes = [routeIds.LOGIN]
