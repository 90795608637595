import createSocket from 'socket.io-client'
import { apiSocketEvents } from './apiSocketEvents'
import { evt } from './eventTypes'
import { deleteLocalStorageToken, getLocalStorageToken } from './localStorage'
import { routeIds } from './routes'
import { dispatch, regFx } from './store'
import { SOCKET_URL } from './config'
import * as R from 'ramda'

export const apiSocket = createSocket(SOCKET_URL, {
  multiplex: false,
  rejectUnauthorized: true,
  autoConnect: false,
  reconnection: true,
  query: {
    token: getLocalStorageToken()
  }
})
window.apiSocket = apiSocket

export const updateSocketAuth = () => {
  apiSocket.io.opts.query = {
    token: getLocalStorageToken()
  }
}
export const reopenSocket = () => {
  updateSocketAuth()
  apiSocket.connect()
}
export const closeSocket = () => {
  apiSocket.close()
}

apiSocket.on('error', err => {
  if (R.prop('type', err) === 'UnauthorizedError') {
    deleteLocalStorageToken()
    dispatch(evt.NAV_TO, [routeIds.LOGIN])
  }
  window.location = /broker/.test(window.location.pathname)
    ? '/broker/'
    : '/remote/'
})

apiSocket.on('connect', () => {
  dispatch(evt.API_SOCKET_CONNECTED)
})

regFx('emitApi', ([type, ...otherArgs]) => {
  return apiSocket.emit(type, ...otherArgs)
})

/* forward events */
apiSocket.on(apiSocketEvents.QUERY_INITIAL, (...a) => {
  dispatch(apiSocketEvents.QUERY_INITIAL, a)
})

apiSocket.on(apiSocketEvents.QUERY_CHANGE, (table, change) => {
  dispatch(apiSocketEvents.QUERY_CHANGE, { table, change })
})
