const darkAccent = '#39A9D6'
const lightAccent = '#54C7F2'
const offAccent = '#A4D05F'
const greenAccent = '#02CBB5'
const darkBluish = '#002959'
const dark = '#4C5A5D'
const placeholderGray = '#7F898B'
const pillGray = '#D8D8D8'
const darkerGray = '#EAEFF1'
const lighterGray = '#F7F9FA'
const darkerGray2 = '#BDC3C5'
const white = '#FFFFFF'
const whitish = '#FAFAFA'
const veryDarkBluish = '#002959'
const alertHighPriority = '#D05F5F'
const alertMediumPriority = '#EAD06D'
const alertLowPriority = '#5FBAD0'
const alertSuccess = '#A4D05F'
const gold = '#CBBD70'

const defaultFont = 'MontSerrat, Helvetica, Arial, sans-serif'
const fontSizeVeryVerySmall = 10
const fontSizeVerySmall = 11
const fontSizeSmall = 13
const fontSizeMedium = 15
const fontSizeLarge = 17
const fontSizeVeryLarge = 20

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightSemiBold = 600
const fontWeightBold = 700

export default {
  fontSizeVeryVerySmall,
  fontSizeVerySmall,
  fontSizeSmall,
  fontSizeMedium,
  fontSizeLarge,
  fontSizeVeryLarge,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightSemiBold,
  fontWeightBold,
  darkBluish,
  darkerGray,
  lightAccent,
  pillGray,
  placeholderGray,
  darkAccent,
  offAccent,
  white,
  whitish,
  gold,
  veryDarkBluish,
  lighterGray,
  darkerGray2,
  dark,
  alertHighPriority,
  alertMediumPriority,
  alertLowPriority,
  alertSuccess,
  navTextColor: veryDarkBluish,
  headerTextColor: darkBluish,
  linkTextColor: darkAccent,
  inputBackgroundColor: lighterGray,
  buttonBackgroundColor: darkerGray,
  activeButtonBackgroundColor: lightAccent,
  buttonColor: dark,
  activeButtonColor: white,
  inputBorder: `1px solid ${darkerGray}`,
  inputFocusBorder: `1px solid ${lightAccent}`,
  inputErrorBorder: '1px solid #D05F5F',
  borderTransition: 'border 200ms',
  actionLinkColor: darkAccent,
  defaultFontFamily: 'MontSerrat, Helvetica, Arial, sans-serif',
  specialBackground: lighterGray,
  placeholderColor: placeholderGray,
  dropdownShadow: `0px 4px 8px -1px rgba(0,0,0,.12)`,
  headerBoxShadow: `0 3px 6px -5px rgba(0,0,0,0.22)`,
  managementSquareBackground: lighterGray,
  managementBorder: `1px solid ${darkerGray}`,
  border: `1px solid ${darkerGray}`,
  greenAccent,
  dashboardSummaryItemFontSize: 28
}
export const severityColor = {
  system: alertSuccess,
  warn: alertLowPriority,
  error: alertMediumPriority,
  critical: alertHighPriority
}
// export const severityColors = ['yellow', '#EAAA6D', '#EAAA6D', '#F26054']
export const severityColors = Object.values(severityColor)
export const typeColorMap = {
  cluster: '#39A9D6',
  network: '#39A9D6',
  host: '#54C7F2',
  storagePool: '#02CBB5',
  guestPool: '#CBBD70',
  guest: '#A4D05F'
}
export const nodeTypes = [
  'cluster',
  'host',
  'storagePool',
  'guestPool',
  'guest'
]
export const nodeColors = nodeTypes.map(n => typeColorMap[n])
export const typeLabelMap = {
  cluster: 'Cluster',
  network: 'Network',
  host: 'Server',
  storagePool: 'Storage Pool',
  guestPool: 'Guest Pool',
  guest: 'Guest VM'
}
export const entityColorsAlpha = {
  cluster: '#39A9D6',
  network: '#39A9D6',
  host: 'rgba(84, 199, 242, 0.2)',
  storagePool: 'rgba(2, 203, 181, 0.2)',
  guestPool: 'rgba(203, 189, 112, 0.2)',
  guest: 'rgba(164, 208, 95, 0.2)'
}

/* Visualization themes */
export const DISCRETE_COLOR_RANGE = [
  '#12939A',
  '#79C7E3',
  '#1A3177',
  '#FF9833',
  '#EF5D28'
]

export const EXTENDED_DISCRETE_COLOR_RANGE = [
  '#19CDD7',
  '#DDB27C',
  '#88572C',
  '#FF991F',
  '#F15C17',
  '#223F9A',
  '#DA70BF',
  '#125C77',
  '#4DC19C',
  '#776E57',
  '#12939A',
  '#17B8BE',
  '#F6D18A',
  '#B7885E',
  '#FFCB99',
  '#F89570',
  '#829AE3',
  '#E79FD5',
  '#1E96BE',
  '#89DAC1',
  '#B3AD9E'
]

export const CONTINUOUS_COLOR_RANGE = ['#EF5D28', '#FF9833']

export const SIZE_RANGE = [1, 10]

export const OPACITY_RANGE = [0.1, 1]
export const OPACITY_TYPE = 'literal'
export const DEFAULT_OPACITY = 1

export const DEFAULT_SIZE = 5

export const DEFAULT_COLOR = DISCRETE_COLOR_RANGE[0]

export const DEFAULT_TICK_SIZE = 7

export const unstyleButton = {
  border: 'none',
  // padding: 0,
  fontFamily: defaultFont,
  appearance: 'none',
  '&:focus': {
    outline: 'none'
  },
  '&:hover': {
    backgroundColor: 'none',
    outline: 'none'
  }
}
