import * as R from 'ramda'
import { evt } from '../eventTypes'
import { regEventFx } from '../store'
import { xor } from '../utils'

regEventFx(evt.CHANGE_VALUE, ({ db }, _, [propPath, value]) => {
  return {
    db: R.assocPath(propPath, value, db)
  }
})

regEventFx(evt.TOGGLE_LIST_VALUE, ({ db }, _, [propPath, value]) => {
  const oldList = R.path(propPath, db)
  const newList = xor([value], oldList)

  return {
    db: R.assocPath(propPath, newList, db)
  }
})
