import * as R from 'ramda'
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Div } from '../utils/styled-utils'
import { CloseX } from '../icons/CloseX'
import { isEmpty } from '../util'
import { PrimaryButton, SecondaryButton } from '../views/shared'
import theme from '../views/theme'
import { dispatch } from '../store'

export const DialogContainer = ({ css, onClick, children }) => (
  <Div
    css={R.merge(
      {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 2,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 41, 89, .2)' //theme.darkBluish,
      },
      css
    )}
    onClick={onClick}
  >
    {children}
  </Div>
)

export const DialogWindow = ({ css, children }) => (
  <Div
    css={R.merge(
      {
        background: 'white',
        boxShadow: theme.dropdownShadow,
        borderRadius: 10,
        display: 'flex'
      },
      css
    )}
  >
    {children}
  </Div>
)

const DialogButtons = ({
  buttonConfig: {
    onOppositeEnds = false,
    borderTrueOrFalse = true,
    leftButton = {},
    rightButton = {}
  } = {}
}) => {
  const {
    label: leftLabel,
    onClick: leftOnClick,
    dispatchEvent: leftDispatchEvent,
    dispatchArgs: leftDispatchArgs,
    disabled: leftDisabled
  } = leftButton
  const {
    label: rightLabel,
    onClick: rightOnClick,
    dispatchEvent: rightDispatchEvent,
    dispatchArgs: rightDispatchArgs,
    disabled: rightDisabled
  } = rightButton
  return (
    <Div
      css={{
        display: 'flex',
        justifyContent: onOppositeEnds ? 'space-between' : 'flex-end',
        borderTop: borderTrueOrFalse && theme.managementBorder,
        paddingTop: 25,
        paddingBottom: 25
      }}
    >
      {!isEmpty(leftButton) && (
        <SecondaryButton
          css={isEmpty(leftButton.css) ? {} : leftButton.css}
          disabled={leftDisabled}
          onClick={() =>
            leftOnClick
              ? leftOnClick()
              : dispatch(leftDispatchEvent, leftDispatchArgs)
          }
        >
          {leftLabel}
        </SecondaryButton>
      )}
      {!isEmpty(rightButton) && (
        <PrimaryButton
          css={R.merge({ marginLeft: 15 }, rightButton.css)}
          disabled={rightDisabled}
          onClick={() =>
            rightOnClick
              ? rightOnClick()
              : dispatch(rightDispatchEvent, rightDispatchArgs)
          }
        >
          {rightLabel}
        </PrimaryButton>
      )}
    </Div>
  )
}

export const AbstractDialogFactory = ({
  windowCss,
  buttonConfig,
  onClose,
  children,
  showCloseX = true
}) => {
  onClose =
    onClose ||
    (() =>
      R.apply(
        dispatch,
        R.pipe(
          R.prop('leftButtonConfig'),
          R.pick(['dispatchEvent', 'dispatchArgs']),
          R.values
        )(buttonConfig)
      ))
  return (
    <DialogContainer>
      <DialogWindow css={windowCss}>
        <Div css={{ minWidth: 33, paddingTop: 15, paddingLeft: 15 }} />

        <Div
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '0%' // fixes children overflowing parent
          }}
        >
          <Div>{children}</Div>
          <DialogButtons buttonConfig={buttonConfig} />
        </Div>

        <Div css={{ paddingTop: 15, paddingRight: 15, minWidth: 33 }}>
          {showCloseX && (
            <Div css={{ cursor: 'pointer' }} onClick={onClose}>
              <CloseX />
            </Div>
          )}
        </Div>
      </DialogWindow>
    </DialogContainer>
  )
}
