import { Svg } from '../utils/styled-utils'
import React from 'react'

export const CloseX = ({ width, height, fill, onClick, ...css }) => (
  <Svg
    {...css}
    width={width || '18px'}
    height={height || '17px'}
    viewBox={`0 0 ${18} ${17}`}
    onClick={onClick}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-961.000000, -230.000000)"
        fill={fill || '#002959'}
      >
        <g transform="translate(440.000000, 208.000000)">
          <polygon points="531.53125 30.5820313 538.476562 37.5546875 536.972656 39.0585938 530 32.0859375 523.027344 39.0585938 521.523437 37.5273438 528.46875 30.5820313 521.523437 23.609375 523.027344 22.1054688 530 29.0507813 536.972656 22.1054688 538.503906 23.609375" />
        </g>
      </g>
    </g>
  </Svg>
)
