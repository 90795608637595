import React from 'react'
import ReactDOM from 'react-dom'
import { Manager, Reference, Popper } from 'react-popper'

export const Tooltip = ({ component: tooltipComponent, children }) => (
  <Manager>
    <Reference>{({ ref }) => <div ref={ref}>{children}</div>}</Reference>
    {ReactDOM.createPortal(
      <Popper
        placement="right"
        modifiers={{
          preventOverflow: { enabled: false },
          flip: { enabled: false }
        }}
        outOfBoundaries={true}
        positionFixed={false}
        eventsEnabled={true}
      >
        {({ ref, style, placement, arrowProps }) => {
          return (
            <div
              ref={ref}
              style={Object.assign({}, { zIndex: 2 }, style)}
              data-placement={placement}
            >
              {tooltipComponent}
              <div ref={arrowProps.ref} style={arrowProps.style} />
            </div>
          )
        }}
      </Popper>,
      document.getElementById('portal')
    )}
  </Manager>
)
