import * as R from 'ramda'
import { evt } from '../eventTypes'
import { regEventFx } from '../store'
import uuid from 'uuid'
import { dispatch } from '../store'

regEventFx(evt.SHOW_ALERT, ({ db }, _, { type, message, duration = 2500 }) => {
  const id = uuid.v4()

  const timeout = duration
    ? setTimeout(() => dispatch(evt.HIDE_ALERT, { id }), duration)
    : null

  return {
    db: R.assocPath(['appAlerts', id], { id, type, message, timeout })
  }
})

regEventFx(evt.HIDE_ALERT, ({ db }, _, { id }) => {
  const alert = R.path(['appAlerts', id], db)

  if (!alert) {
    return { db }
  }

  clearTimeout(alert.timeout)

  return {
    db: R.dissocPath(['appAlerts', id])
  }
})
