import axios from 'axios'
import * as R from 'ramda'
import { dispatch, regFx } from './store'
import { isEmpty, isPlainObject } from './util'
import { evt } from './eventTypes'

const axiosFx = ({
  method = 'get',
  url,
  headers,
  data,
  success,
  failure,
  ...rest
}) => {
  let successEvent = success
  let successArgs = []
  let failureEvent = failure
  let failureArgs = []

  if (Array.isArray(success)) {
    successEvent = R.head(success)
    successArgs = R.tail(success)
  }
  if (Array.isArray(failure)) {
    failureEvent = R.head(failure)
    failureArgs = R.tail(failure)
  }

  axios({
    method,
    url,
    headers,
    data
  })
    .then(
      res =>
        successEvent &&
        dispatch(
          successEvent,
          isEmpty(successArgs) ? res : [res, ...successArgs]
        )
    )
    .catch(e => {
      if (R.pathOr(500, ['response', 'status'])(e) === 401) {
        dispatch(evt.LOGOUT_REQUEST)
        return
      }
      failureEvent &&
        dispatch(failureEvent, isEmpty(failureArgs) ? e : [e, ...failureArgs])
    })
}

regFx('axios', axiosFx)

const dispatchFx = args => {
  if (isPlainObject(R.nth(1, args))) {
    dispatch(R.head(args), R.nth(1, args))
  } else {
    dispatch(R.head(args), R.tail(args))
  }
}

regFx('dispatch2', dispatchFx)
const dispatchNFx = args => {
  if (!Array.isArray(args) && R.all(x => Array.isArray(x), args)) {
    throw new Error('dispatchN: Expected arguments to be a 2d array')
  }
  args.forEach(a => {
    if (isPlainObject(R.nth(1, a))) {
      dispatch(R.head(a), R.nth(1, a))
    } else {
      dispatch(R.head(a), R.tail(a))
    }
  })
}

regFx('dispatchN2', dispatchNFx)
