import * as R from 'ramda'
import { curry } from 'ramda'
import * as RA from 'ramda-adjunct'
import v from 'voca'
import { format, distanceInWords } from 'date-fns'

export const titleOneCap = str => v.capitalize(v.words(str).join(' '))
export const titleAllCap = str => v.titleCase(v.words(str).join(' '))

const k = 1024
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
export const formatBytes = curry((decimals, unit, bytes) => {
  const unitIndex = sizes.indexOf(unit)
  const i =
    unitIndex === -1 ? Math.floor(Math.log(bytes) / Math.log(k)) : unitIndex
  const finalUnit = sizes[i]
  if (bytes === 0) return `0 ${finalUnit}`
  const dm = decimals || 2
  const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
  return `${isNaN(value) ? '?' : value} ${finalUnit}`
})
export const formatGb1 = formatBytes(1, 'GB')

export const formatMemory = x => (RA.isValidNumber(x) ? x / 1024 + ' GB' : '?')
//formatBytes(1, 'GB', x * 1000000)

export const toArrayAsNeeded = val => (R.is(Array, val) ? val : [val])
export const childPath = (path1, path2) =>
  toArrayAsNeeded(path1).concat(toArrayAsNeeded(path2))

export const formatStandardDate = d => format(d, 'MM-DD-YYYY HH:mm')
export const formatDate2 = d => format(d, 'MMM D, YYYY')

// TODO. cpu,mem sometimes array. Need to know correct format e.g. [1 1] [4096 4096] #86
export const formatCpuArray = a => `${R.head(a)}`
export const formatMemArray = a => `${formatMemory(R.head(a))}`

export const fromNow = date => distanceInWords(new Date(), date)

export const makeFullId = (type, id) => `${type}-$$$-${id}`
export const nodeToFullId = node => {
  const { type, id } = node
  return makeFullId(type, id)
}
export const extractTypeAndId = fullId => R.split('-$$$-', fullId)
export const extractId = fullId => R.last(extractTypeAndId(fullId))
