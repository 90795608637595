import { assoc, assocPath, find, propEq } from 'ramda'
import * as R from 'ramda'
import { evt, failure, success } from '../eventTypes'
import { API_BASE_URL } from '../config'
import { getLocalStorageToken } from '../localStorage'
import { routeIds, routes } from '../routes'
import { regEventFx } from '../store'
import { getRouteParams } from '../subs'
import { stringify as queryString } from 'qs'
import { dispatch } from '../store'

regEventFx(evt.INITIALIZE_DB, (db, _, display) => {
  return {
    db: {
      settings: { display, license: null }
    },
    dispatch: [
      evt.GET_CLUSTERS,
      {
        successBranch: ({ data }) => {
          const id = R.path(['id'], data)
          return {
            db: R.assocPath(['settings', 'clusterId'], id),
            dispatch2: [evt.GET_LICENSE]
          }
        }
      }
    ]
  }
})

regEventFx(evt.SOCKET_CONNECTED, () => ({
  db: assoc('connected', true),
  emitN: []
}))

regEventFx(evt.SOCKET_DISCONNECTED, () => ({
  db: assoc('connected', false)
}))

regEventFx(evt.COLLECTION_INITIAL_VALUE, ({ db }, _, [k, v]) => {
  return {
    db: R.assocPath(['data', k], v)
  }
})

regEventFx(evt.GET_CLUSTERS, ({ db }, _, { successBranch } = {}) => {
  return {
    dispatch2: [
      evt.API_REQUEST,
      {
        method: 'get',
        url: '/host/clusterid',
        success: [success(evt.GET_CLUSTERS), successBranch],
        failure: failure(evt.GET_CLUSTERS)
      }
    ]
  }
})

regEventFx(success(evt.GET_CLUSTERS), ({ db }, _, [res, branch]) => {
  return branch(res)
})

regEventFx(failure(evt.GET_CLUSTERS), ({ db }, _, res) => {
  console.error('failed to get cluster id', res)
})

regEventFx(evt.GET_LICENSE, ({ db }, _, __) => {
  const clusterId = R.path(['settings', 'clusterId'])(db)
  return {
    dispatch2: [
      evt.API_REQUEST,
      {
        method: 'get',
        url: `/cluster/${clusterId}/license`,
        success: success(evt.GET_LICENSE),
        failure: failure(evt.GET_LICENSE)
      }
    ]
  }
})

regEventFx(success(evt.GET_LICENSE), ({ db }, _, res) => {
  return { db: R.assocPath(['settings', 'license'], res.data) }
})

regEventFx(failure(evt.GET_LICENSE), ({ db }, _, res) => {
  setTimeout(() => dispatch(evt.GET_LICENSE), 5000)
  return {
    db: R.assocPath(['settings', 'license'], {})
  }
})

/* Some routing. Optimistically set in db to make sure effects are immediate */
regEventFx(evt.NAV_TO, ({ db }, _, [id, params, options]) => {
  const existingParams = getRouteParams(db)
  const nextParams = R.prop('mergeParams', options)
    ? R.merge(existingParams, params)
    : params || existingParams
  return {
    db: assocPath(['router', 'match'], {
      params: nextParams,
      route: find(propEq('id', id), routes)
    }),
    route: [id, nextParams]
  }
})

/* Manage generic API requests */
regEventFx(evt.API_REQUEST, ({ db }, _, args) => {
  const token = getLocalStorageToken()
  const { url, query, headers, fullUrl } = args
  let qs
  if (query) {
    qs = queryString(query)
  }

  return {
    axios: R.merge(qs ? R.assoc('data', qs, args) : args, {
      url: fullUrl || API_BASE_URL + url,
      headers: R.merge(
        token ? { Authorization: 'Bearer ' + token } : {},
        headers ? headers : {},
        qs ? { 'Content-Type': 'application/x-www-form-urlencoded' } : {}
      )
    })
  }
})

regEventFx(failure(evt.GET_USER), (__, _, err) => {
  if (R.path(['response', 'status'], err) === 401)
    return {
      route: [routeIds.LOGIN, {}]
    }
})

export const axiosConfig = (db, args) => {
  const { url, query, fullUrl, token: token$ } = args
  const token = token$ || getLocalStorageToken()
  let qs
  if (query) {
    qs = queryString(query)
  }
  return R.merge(qs ? R.assoc('data', qs, args) : args, {
    url: fullUrl || API_BASE_URL + url,
    headers: R.merge(
      token ? { Authorization: 'Bearer ' + token } : {},
      qs ? { 'Content-Type': 'application/x-www-form-urlencoded' } : {}
    )
  })
}
